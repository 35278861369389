import bnbIcon from './assets/bnb_icon.svg';
import theterIcon from './assets/theter_icon.svg';
import arbitrumIcon from './assets/arbitrum_icon.svg';
import ethereumIcon from './assets/ethereum_icon.svg';

export type Item = { id: number, name: string; slug?: string; icon: string };

export const gridBlockDefaultStyles = {
  padding: '16px',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}

export const tokens: Item[] = [
  {
    id: 1,
    slug: 'cCNTP',
    name: 'CNTP',
    icon: bnbIcon,
  },
  {
    id: 2,
    slug: 'conet',
    name: 'Conet',
    icon: theterIcon,
  },
  /* {
    id: 3,
    slug: 'ethereum',
    name: 'Ethereum',
    icon: ethereumIcon,
  } */
]

export const networks: Item[] = [
  {
    id: 1,
    name: 'Arbitrum',
    icon: arbitrumIcon,
  }, {
    id: 2,
    name: 'Ethereum',
    icon: ethereumIcon,
  }
]