export const sideBarEn = {
  dashboard: "Dashboard",
  leaderboard: "Leaderboard",
  apps: "Apps",
  swap: "Swap",
  chat: "Chat",
  claim: "Airdrop",
  language: "Language",
  new: "New",
  theme: "Theme",
  admin: "Admin Monitor",
};

export const sideBarZh = {
  dashboard: "仪表板",
  leaderboard: "排行榜",
  apps: "应用商店",
  swap: "交换",
  chat: "聊天应用",
  claim: "领取",
  language: "语言",
  new: "新",
  theme: "主题",
  admin: "管理监控器",
};
