import { Locale } from "../../../../localization/types"
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useLanguage } from "../../../../API/useLanguage"
import { ReactComponent as IconUsa } from "../../../../assets/images/usa.svg";
import { ReactComponent as IconChina } from "../../../../assets/images/china.svg";
import SvgIcon from "@mui/icons-material/Check";
import styled from "styled-components";
import { Box, ClickAwayListener, Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import useAppState from "../../../../store/appState/useAppState";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  gap: "8px",
}));

type action = {
  icon: any;
  name: Locale;
  description: string;
  active: boolean;
};

const actions: action[] = [
  {
    icon: IconChina,
    name: "zh",
    description: "中文",
    active: true
  },
  {
    icon: IconUsa,
    name: "en",
    description: "English",
    active: true
  },
];

const LanguageSwitcher = () => {
  const intl = useIntl();
  const { locale, setLocale } = useAppState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  /* const {i18n} = useTranslation() */
  const { changeLanguage } = useLanguage()
  const { t, i18n } = useTranslation()

  const languageIcon = useMemo(() => {
    switch (i18n.language) {
      case "en":
        return IconUsa;
      case "zh":
        return IconChina;
      default:
        return IconChina;
    }
  }, [i18n.language]);

  const languageName = useMemo(() => {
    switch (i18n.language) {
      case "en":
        return "English";
      case "zh":
        return "中文";
      default:
        return "中文";
    }
  }, [i18n.language]);

  const LanguageSelector = styled(Box)(({ theme }) => ({
    cursor: "pointer",
    height: "40px",
    alignItems: "center",
    display: "flex",
    borderRadius: "16px",
    gap: "8px",
    justifyContent: "flex-start",
    "&:hover": {
      background: localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59",
    },
  }));

  const languageMenuClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleCloseDropdown = () => {
    setIsDropdownOpen(false);
  };

  const selectLanguage = (locale: Locale) => {
    setLocale(locale);
    setIsDropdownOpen(false);
  };

  return (
    <Box position="relative" flexGrow={1} width="100%">
      <LanguageBox onClick={languageMenuClick}>
        <SvgIcon
          cursor="pointer"
          component={languageIcon}
          inheritViewBox
          sx={{ fontSize: 32 }}
        />
        <Box fontWeight='bold'>{languageName}</Box>
      </LanguageBox>

      {isDropdownOpen ? (
        <ClickAwayListener onClickAway={handleCloseDropdown}>
          <Box
            width="200px"
            height="180px"
            position="absolute"
            bottom="50px"
            left={"40%"}
            zIndex={9999}
            bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#262527"}
            border={'1px solid white'}
            display="flex"
            flexDirection="column"
            borderRadius="16px"
            boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
            p="1rem"
            gap="32px"
          >
            <Stack justifyContent="space-between" direction="row">
              <Typography fontWeight='bold' >{t('sideBar.language')}</Typography>
              <SvgIcon
                cursor="pointer"
                onClick={handleCloseDropdown}
                component={CloseIcon}
                inheritViewBox
                sx={{ fontSize: 16 }}
              />
            </Stack>

            <Stack direction="column" gap="8px">
              {actions.map((language, index) => (
                <LanguageSelector
                  key={index}
                  px="1rem"
                  onClick={() => {
                    if (language.active)
                      changeLanguage(language.name)
                  }}
                >
                  <SvgIcon
                    cursor="pointer"
                    component={language.icon}
                    inheritViewBox
                    sx={{ fontSize: 32 }}
                  />

                  {language.description}

                  {locale === language.name ? (
                    <SvgIcon
                      cursor="pointer"
                      component={CheckIcon}
                      inheritViewBox
                      sx={{ fontSize: 24 }}
                    />
                  ) : null}
                </LanguageSelector>
              ))}
            </Stack>
          </Box>
        </ClickAwayListener>
      ) : null}
    </Box>
  );
};

export default LanguageSwitcher;