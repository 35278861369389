export const chatEn = {
  "chats": "Chats",
  "searchPlaceholder": "Search wallets...",
  "recent": "Recent",
  "send": "Send",
}

export const chatZh = {
  "chats": "聊天",
  "searchPlaceholder": "搜索钱包...",
  "recent": "最近",
  "send": "发送",
};
