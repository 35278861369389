import { Close, Search } from '@mui/icons-material';
import { Modal, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Item } from '../common';

type SelectModalProps = {
  title: string;
  name: string;
  searchPlaceholder: string;
  items: Item[],
  handleItemSelect: (value: Item) => void;
  inputAction?: (value: string) => any;
  open: boolean;
  onClose: () => void;
}

export default function SelectModal({ title, name, searchPlaceholder, items, inputAction, handleItemSelect, open, onClose }: SelectModalProps) {
  const theme = useTheme();

  const [hoveredOption, setHoveredOption] = useState<number>();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const filteredItems = useMemo(() => items.filter(
    (item) =>
      searchTerm
        ? item.slug?.toUpperCase().includes(searchTerm.toUpperCase()) || item.name.toUpperCase().includes(searchTerm.toUpperCase())
        : true
  ), [searchTerm]);

  function handleInputEnter(e: KeyboardEvent) {
    e.stopPropagation();

    if (e.key !== "Enter" || !inputAction || !searchTerm) return;

    inputAction?.(searchTerm);
  }

  useEffect(() => {
    if (!inputAction) return;

    function handleInputEnter(e: KeyboardEvent) {
      e.stopPropagation();

      if (e.key !== "Enter" || !inputAction || !searchTerm) return;

      setSearchTerm('');
      inputAction?.(searchTerm);
    }

    document.addEventListener('keydown', handleInputEnter);

    return () => document.removeEventListener('keydown', handleInputEnter);
  })

  return (
    <Modal open={open} onClose={onClose} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Stack
        padding="16px" border="1px solid #4C4C4C"
        bgcolor={theme.palette.mode === "light" ? "#F8F8FA" : "#262626"} gap="24px" borderRadius="24px"
        width="95%" maxWidth="472px" height="520px"
      >
        <Stack gap="8px">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography fontWeight="700">{title}</Typography>
            <button style={{ all: "unset", cursor: "pointer" }} onClick={onClose}>
              <Close sx={{ color: theme.palette.mode === "light" ? "#000" : "#FFF" }} />
            </button>
          </Stack>
          <Stack direction="row" gap="8px" alignItems="center" bgcolor={theme.palette.mode === "light" ? "#F0F0F2" : "#0D0D0D"} padding="8px" borderRadius="1000px">
            <Search sx={{ color: theme.palette.mode === "light" ? "#000" : "#FFF" }} />
            <input
              type="string" placeholder={searchPlaceholder}
              value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}
              style={{ background: "none", color: "#989899", flex: "1", border: "none" }}
            />
          </Stack>
        </Stack>
        <Stack gap="16px">
          <Typography fontSize="14px" color="#989899">{name}</Typography>
          <Stack>
            {
              !!filteredItems.length ? (
                filteredItems.map((item, index) => (
                  <button className="item-button" onMouseOver={() => setHoveredOption(index)} onMouseOut={() => setHoveredOption(undefined)} onClick={() => {
                    handleItemSelect(item);
                    onClose();
                  }}
                    style={{ background: hoveredOption === index ? theme.palette.mode === "light" ? "#F0F0F2" : "#3F3F40" : "transparent" }}
                  >
                    <img src={item.icon} alt={item.name} width={32} height={32} />
                    <Stack gap="8px" alignItems="flex-start">
                      <Typography fontWeight="700" fontSize="14px" color={theme.palette.mode === "light" ? "#000" : "#FFF"}>{item.name}</Typography>
                      {
                        item.slug && (
                          <Typography fontSize="12px" color="#989899">
                            {
                              name === 'Wallets'
                                ? item.slug.substring(0, 2) + item.slug.substring(2, 7).toUpperCase() + '...' + item.slug.substring(item.slug.length - 5, item.slug.length).toUpperCase()
                                : item.slug.toUpperCase()
                            }
                          </Typography>
                        )
                      }
                    </Stack>
                  </button>
                ))
              ) : (
                <Typography color="#989899" fontWeight="500" textAlign="center">No results found. Hit enter to use the typed address.</Typography>
              )
            }
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  )
}