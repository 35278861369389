export function timeAgo(dateString: string) {
  const [datePart, timePart] = dateString.split(', ');
  const [day, month, year] = datePart.split('/').map(Number);
  const [hours, minutes, seconds] = timePart.split(':').map(Number);

  const inputDate = new Date(year, month - 1, day, hours, minutes, seconds);
  const now = new Date();
  // @ts-ignore: Unreachable code error
  const diffInSeconds = Math.floor((now - inputDate) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInMonth = 2592000; // Approximation (30 days)
  const secondsInYear = 31536000; // Approximation (365 days)

  if (diffInSeconds < secondsInMinute) {
    return `${diffInSeconds}s`;
  } else if (diffInSeconds < secondsInHour) {
    return `${Math.floor(diffInSeconds / secondsInMinute)}m`;
  } else if (diffInSeconds < secondsInDay) {
    return `${Math.floor(diffInSeconds / secondsInHour)}hr`;
  } else if (diffInSeconds < secondsInMonth) {
    return `${Math.floor(diffInSeconds / secondsInDay)}d`;
  } else if (diffInSeconds < secondsInYear) {
    return `${Math.floor(diffInSeconds / secondsInMonth)}mon`;
  } else {
    return `${Math.floor(diffInSeconds / secondsInYear)}y`;
  }
}