export const adminMonitorEn = {
  admin: "Admin",
  monitor: "Monitor",
  totalAssets: "Total Assets",
}

export const adminMonitorZh = {
  admin: "管理员",
  monitor: "监控",
  totalAssets: "总资产",
};
