export const formatIpAddr = (addr: string | undefined) => {

  if (!addr) {
    return ''
  }

  let result = addr.substring(0, 2) +
    addr.substring(2, 6).toUpperCase() +
    "...." +
    addr.substring(addr.length - 4, addr.length).toUpperCase();

  return result
}