import { Button } from '@mui/base';
import { ArrowForwardRounded } from '@mui/icons-material';
import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

export interface IAppCardsProps {
    appImageWhite: string;
    appImageBlack: string;
    title: string;
    description: string;
    appUrl?: string;
}


export default function AppCard({ appImageWhite, appImageBlack, title, appUrl, description }: IAppCardsProps) {
    const theme = useTheme()
    const { t } = useTranslation()

    return (
        <motion.div
            className="box"
            whileHover={{ scale: [null, 1.05, 1.04] }}
            transition={{ duration: 0.3 }}
        >
            <Stack
                width={'200px'}
                minWidth={'200px'}
                minHeight={'250px'}
                height={'fit-content'}
                style={{ cursor: 'pointer' }}
                bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#fff" : "#262527"}
                border={`1px solid ${localStorage.getItem('mui-mode') === 'light' ? "#E4E2E4" : "#474648"}`}
                borderRadius={'16px'}
                onClick={() => window.open(appUrl, '_blank')}
            >
                <img src={localStorage.getItem('mui-mode') === 'light' ? appImageWhite : appImageBlack} alt="logoImge" width={'100%'} />

                <div style={{ padding: '24px' }}>

                    <Typography color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#fff"} fontWeight={700} minHeight={'48px'}>{title}</Typography>

                    <div style={{ marginTop: '16px' }}>
                        <Typography color={"#858586"} fontWeight={400} fontSize={'12px'}>{description}</Typography>
                    </div>
                </div>
            </Stack >
        </motion.div>

    )
}