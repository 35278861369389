export const claimEn = {
  title1: "Airdrop",
  bannerTitle: "$CONET Live Soon!",
  bannerCaption:
    "The Token Generation Event (TGE) for $CONET will begin soon — join the revolution now!",
  yourAssets: "Your assets",
  timeToReclaim: "Time to Reclaim your rewards",
  timeLeft: "Time left",
  claimableCntp: "Claimable CNTP",
  claimReward: "Get your Reward",
  checkReward: "Check Reward",
  cntpLeft: "CNTP left",
  howItWorks: "How it works",
  claimCntpBonus: {
    title: "Claim your CNTP Bonus",
    description: "Guardians and Conetians can claim their rewards.",
  },
  bestOfLuck: {
    title: "Best of Luck!",
    description:
      "All wallets eligible to claim will automatically join the Lucky Draw that will be hosted on our channels.",
  },
  moreNfts: {
    title: "More NFTs = More Chances to Win!",
    description:
      "Each NFT you own increases your chance to take home the big prizes.",
  },

  christmasLiveDate: "December, 24th 8am UTC",
  christmasLiveWinners: {
    first: "1 winner - 1,000,000 CNTP",
    second: "2 winners - 500,000 CNTP",
    third: "4 winners - 250,000 CNTP",
    fourth: "10 winners - 100,000 CNTP",
  },
  guardianNft: "Guardian NFT",
  conetianNft: "CoNETian NFT",
  balance: "Balance",
  comingSoon: "Claiming Soon",
  claimAllocation: "Claim Allocation",
  guardian: "Guardian",
  conetian: "CoNETian",
  miner: "Free Miners, Guardians, or CoNETians",
  airdropAllocation: "Airdrop Allocation",
  forEachPlan: "for each plan",
  receive: "Receive",
  card1: {
    description: {
      first: "Unlock after 5 years followed by a 12-month vesting.",
    },
    descriptionDetail: "Available prior to TGE",
  },
  card2: {
    description: {
      first: "25% unlocked immediately.",
      second: "75% will vest linearly over 6 months.",
    },
    descriptionDetail: "Available prior to TGE",
  },
  card3: {
    description: {
      first: "30% of the airdropped tokens will be unlocked immediately.",
      second: "70% will vest linearly over 6 months.",
    },
    descriptionDetail: "Available for eligible participants",
  },
};

export const claimZh = {
  title1: "领取",
  title2: "资产",
  bannerTitle: "$CONET 即将上线！",
  bannerCaption: "$CONET的代币生成事件（TGE）即将开始 – 立 即加入变革吧！",
  yourAssets: "你的资产",
  timeToReclaim: "领取奖励的时间",
  timeLeft: "剩余时间",
  claimableCntp: "可领取的 CNTP",
  claimReward: "获取奖励",
  checkReward: "查看奖励",
  cntpLeft: "剩余CNTP",
  howItWorks: "如何运作",
  claimCntpBonus: {
    title: "领取您的 CNTP 奖励",
    description: "守护者和 Conetians 可以领取他们的奖励。",
  },
  bestOfLuck: {
    title: "祝你好运！",
    description: "所有符合领取条件的钱包将自动加入我们频道举办的幸运抽奖。",
  },
  moreNfts: {
    title: "更多 NFT = 更多获胜机会！",
    description: "您拥有的每个 NFT 都会增加赢得大奖的机会。",
  },
  christmasLiveDate: "12月24日 上午8点 UTC",
  christmasLiveWinners: {
    first: "1 名中奖者 - 1,000,000 CNTP",
    second: "2 名中奖者 - 500,000 CNTP",
    third: "4 名中奖者 - 250,000 CNTP",
    fourth: "10 名中奖者 - 100,000 CNTP",
  },
  guardianNft: "守护者 NFT",
  conetianNft: "CoNETian NFT",
  balance: "余额",
  comingSoon: "敬请期待",
  claimAllocation: "领取资产",
  guardian: "守护者",
  conetian: "CoNETian",
  miner: "免费矿工、守护者或CoNETian",
  airdropAllocation: "空投分配",
  forEachPlan: "每个计划",
  receive: "接收",
  card1: {
    description: {
      first: "5年锁定期后，将在12个月内线性解锁",
    },
    descriptionDetail: "TGE 之前可用",
  },
  card2: {
    description: {
      first: "25% 立即解锁",
      second: "75%将在6个月内线性解锁",
    },
    descriptionDetail: "TGE 之前可用",
  },
  card3: {
    description: {
      first: "30% 立即解锁",
      second: "70%将在6个月内线性解锁",
    },
    descriptionDetail: "适用于符合条件的参与者",
  },
};
