import create from "zustand";

interface ILeaderboards {
    leaderboards: any
    wallets: any[]
    referees: any[]
    oracleAssets: any
    monitoredWallets: any[]
    showAdminMonitor: boolean
    claimedCntpInChristmas2024Event: number
    setLeaderboards: (leaderboards: any) => void
    setWallets: (wallets: any[]) => void
    setReferees: (referees: any[]) => void
    setOracleAssets: (oracleAssets: any) => void
    setMonitoredWallets: (monitoredWallets: any[]) => void
    setShowAdminMonitor: (showAdminMonitor: boolean) => void
    setClaimedCntpInChristmas2024Event: (claimedCntpInChristmas2024Event: number) => void
}


export const useRefreshStore = create<ILeaderboards>((set) => ({
    leaderboards: undefined,
    wallets: [],
    referees: [],
    oracleAssets: undefined,
    monitoredWallets: [],
    showAdminMonitor: false,
    claimedCntpInChristmas2024Event: 0,
    setLeaderboards: (leaderboards: any) => set({ leaderboards }),
    setWallets: (wallets: any) => set({ wallets }),
    setReferees: (referees: any) => set({ referees }),
    setOracleAssets: (oracleAssets: any) => set({ oracleAssets }),
    setMonitoredWallets: (monitoredWallets: any) => set({ monitoredWallets }),
    setShowAdminMonitor: (showAdminMonitor: boolean) => set({ showAdminMonitor }),
    setClaimedCntpInChristmas2024Event: (claimedCntpInChristmas2024Event: number) => set({ claimedCntpInChristmas2024Event })
}));
