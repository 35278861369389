import { Box, SvgIcon, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ProfileData } from '../../../../store/appState/appStateReducer';
import { formatIpAddr } from '../../../../utilities/formatIpAddr';
import { Chat, Message } from './types';
import { ReactComponent as ConetAvatar } from "../../../../assets/images/con-avatar.svg";
import { FaSearch, FaUser } from 'react-icons/fa';
import { BsThreeDots } from 'react-icons/bs';
import { CgArrowsExchange } from 'react-icons/cg';
import { BiChevronLeft } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

interface MessagesProps {
  profile: ProfileData | null,
  currentChat?: Chat,
  addMessage: (message: Message) => void,
  close: () => void,
  isMobile: boolean,
}

export default function Messages({ currentChat, addMessage, profile, close, isMobile }: MessagesProps) {
  const [message, setMessage] = useState<string>('');

  const theme = useTheme();
  const { t } = useTranslation();

  function sendNewMessage() {
    if (!profile) return;

    const messageDto = {
      id: 3,
      sentBy: profile?.keyID,
      content: message,
      date: new Date()
    }

    addMessage(messageDto);
    setMessage('');
  }

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key !== "Enter") return;
      sendNewMessage();
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [message])

  return (
    <Box className={`messages-wrapper border ${theme.palette.mode === "dark" ? "dark-block" : "light-block"}`}>
      {
        currentChat && (
          <Box className="messages-header border">
            <div className="user">
              {
                isMobile && (
                  <button style={{ background: "none", border: "none", cursor: "pointer" }} onClick={close}>
                    <BiChevronLeft size={28} color="#ffffff" />
                  </button>
                )
              }
              <SvgIcon
                component={ConetAvatar}
                inheritViewBox
                sx={{ fontSize: 32 }}
              />
              <p>{formatIpAddr(currentChat.user)}</p>
            </div>
            <div className="options">
              <FaSearch size={24} />
              <FaUser size={24} />
              <BsThreeDots size={24} />
            </div>
          </Box>
        )
      }
      <Box
        className="messages-content"
      >
        {
          currentChat && currentChat.messages.map((message: any) => (
            <Box className={`message ${profile?.keyID === message.sentBy ? 'current' : ''}`}>
              <p style={{ opacity: .4, fontSize: "14px" }}>{formatIpAddr(message.sentBy)}:</p>
              <p>{message.content}</p>
              <p style={{ alignSelf: "end", opacity: .4, fontSize: "14px", marginTop: "10px" }}>{message.date.toLocaleString()}</p>
            </Box>
          ))
        }
      </Box>
      {
        currentChat && (
          <Box sx={{ display: "flex", gap: ".8rem", padding: "1rem" }}>
            <input
              id="message-input"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="chat-input border"
            />
            <button onClick={sendNewMessage} className="input-message-button border">
              {t("chat.send")}
            </button>
            <button className="input-message-button border">
              <CgArrowsExchange size={28} />
            </button>
          </Box>
        )
      }
    </Box>
  )
}