export const unlockWalletEn = {
  welcome: "Welcome Back",
  titleWhite: "Access your",
  titleBlue: "wallet",
  password: "Enter your password",
  inputPassword: "Minimum 6 character",
  invalidPassword: "Invalid Password",
  forgot: "Forgot your password",
  unlock: "Unlock",
};

export const unlockWalletZh = {
  welcome: "欢迎回来",
  titleWhite: "进入您的",
  titleBlue: "钱包",
  password: "输入您的密码",
  inputPassword: "最少 6 个字符",
  invalidPassword: "密码无效",
  forgot: " 忘记密码",
  unlock: "解锁",
};
