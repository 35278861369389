import { useEffect, useState } from 'react';
import { styled as materialStyled, Button, Paper, TextField, Typography, Box, SvgIcon, ClickAwayListener, Stack, InputBase } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useColorScheme } from '@mui/material-next/styles';
import { ReactComponent as ConetAvatar } from "../../../../../assets/images/con-avatar.svg";
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CheckIcon from '@mui/icons-material/Check';
import { platform } from '../../../../../API/platform';
import store, { useTypedSelector } from '../../../../../store/store';
import { profile } from '../../../../../API';

import { setCurrentProfileCNTP, setPass } from '../../../../../store/appState/appStateActions';

import { toast } from 'react-toastify';
import { ButtonLabel, ContainerButton } from '../../dashboard/styles';

import CircularProgress from '@mui/material/CircularProgress';
import { useFetchData } from "../../../../../API/refresh";
import { useUserProfile } from '../../../../../API/useUserProfile';
import { useTranslation } from 'react-i18next';

interface IInfoCard {
  currentProfile: any;
}

const InputStyle = materialStyled(Paper)(() => ({
  display: 'flex',
  textAlign: 'center',
  marginBottom: '16px',
  borderRadius: '1rem',
  border: '1px solid #E4E2E4',

  width: '100%',
  height: '52px',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  justifyContent: 'center'
}))

const InfoCard = (props: IInfoCard) => {
  const intl = useIntl();
  const { mode, setMode } = useColorScheme();
  const [showPrivateKeyModal, setShowPrivateKeyModal] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [keyID, setKeyId] = useState<string>();
  const [shortId, setShortId] = useState<string>();
  const [inputText, setInputText] = useState<string>();
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [isMoreOpen, setMoreOpen] = useState(false);
  const [isTagOpen, setTagOpen] = useState(false);
  const [tagColor, setTagColor] = useState<string>();
  const [conetPlatform, setConetPlatform] = useState<any>('')
  const [workerLoading, setWorkerLoading] = useState(0)
  const [profileImage, setProfileImage] = useState('')
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false)
  const conet_platform = new platform(setConetPlatform, setWorkerLoading)
  const [profileVer, setProfileVer] = useState<number>(0)
  const [copyIsLoading, setCopyIsLoading] = useState<boolean>(false)
  const [isPasswordCorrect, setIsPasswordCorrect] = useState<boolean>(false)

  const { wallet, referees, leaderboard } = useFetchData();
  const { userProfile, setUserProfile } = useUserProfile();

  const { t } = useTranslation()

  const authKey = useTypedSelector(state => state.appState.pass)
  useEffect(() => {
    const shortID = props?.currentProfile?.keyID?.substring(0, 2) + props?.currentProfile?.keyID?.substring(2, 8).toUpperCase() + '...' + props?.currentProfile?.keyID?.substring(props?.currentProfile?.keyID?.length - 6, props?.currentProfile?.keyID?.length).toUpperCase()

    setKeyId(props?.currentProfile?.keyID);
    setInputText(props?.currentProfile?.data ? props?.currentProfile?.data?.nickname : intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' }))
    setShortId(shortID);
    setTagColor(props?.currentProfile?.data?.tags);
    setProfileImage(props?.currentProfile?.data?.profileImg ? props?.currentProfile.data.profileImg : '')
  }, []);

  function convertImageToBase64(fileInput: HTMLInputElement) {

    const file = fileInput.files?.[0];

    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        alert("Please select a valid image file (PNG, JPG, JPEG).");
        return;
      }

      const reader = new FileReader();
      reader.onload = function () {
        const base64String = reader.result as string;
        setProfileImage(base64String)
      };
      reader.readAsDataURL(file);
    }
  }

  const removeImg = () => {
    setProfileImage('')
  }

  const handlePictureUpload = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/png, image/jpeg, image/jpg";
    fileInput.addEventListener("change", () => { convertImageToBase64(fileInput); setProfileOpen(false) });
    fileInput.click();
  }

  const handleCopyToClipboard = () => {
    if (keyID) {
      navigator.clipboard.writeText(keyID).then(function (x) {
        toast.success('Wallet copied to the clipboard', { position: "bottom-center", autoClose: 1500 })
      });

    } else {
      toast.error('Unable to get the Wallet id', { position: "bottom-center", autoClose: 1500 })
    }
  };

  const handleCopyToClipboardPrivateFinal = async () => {
    setCopyIsLoading(true);

    await navigator.clipboard.write([
      new ClipboardItem({
        "text/plain": new Promise(async resolve => {
          const [status, keyPasscode] = await conet_platform.testPasscode(password, setProfileVer);

          if (status === true) {
            const savedPrivate = props.currentProfile?.privateKeyArmor;
            store.dispatch(setPass(keyPasscode));
            toast.success('Private Key copied to the clipboard', { position: "bottom-center", autoClose: 1500 })
            return resolve(new Blob([savedPrivate], { type: "text/plain" }))
          }
          toast.error('Unable to get the private key', { position: "bottom-center", autoClose: 1500 });
          return resolve(new Blob([''], { type: "text/plain" }))
        })
      })
    ])
    setCopyIsLoading(false);
  };

  const handleCopyToClipboardPrivate = async () => {
    setCopyIsLoading(true);

    if (password.length > 5) {
      // const savedPrivate = props.currentProfile?.privateKeyArmor;
      const res = await conet_platform.testPasscode(password, setProfileVer);

      if (res[0] === true) {
        setIsPasswordCorrect(true)
      }
      setCopyIsLoading(false);
    };

  };

  const user = props.currentProfile
  const handleSend = async () => {

    setSaveIsLoading(true)

    const newProfile: profile = {
      nickname: inputText || 'User',
      tags: tagColor,
      isPrimary: true,
      alias: 'current user',
      bio: '',
      profileImg: profileImage
    }

    const newData = { ...user, data: newProfile }
    const res_update = await conet_platform.updateProfile(authKey, newData)

    const res_getall = wallet && wallet.length > 0 ? wallet : []

    for (let i = 0; i < res_getall[0].length; i++) {
      if (res_getall[0][i]?.keyID && newData.keyID == res_getall[0][i]?.keyID) {
        //await store.dispatch(setActiveProfile(res_getall[0][i]));
        setUserProfile(res_getall[0][i])
        setCurrentProfileCNTP(res_getall[0][i])
      }
    }

    setSaveIsLoading(false)
    if (res_update.length > 0) {
      toast.success('Profile updated', { position: 'bottom-center', autoClose: 1500 })
    } else {
      toast.error('An error occurred', { position: 'bottom-center', autoClose: 1500 })
    }

    // store.dispatch(setActiveProfile(res_getall[0]));
  };

  const selectColorTag = (color: string) => {
    setTagColor(color);
    setTagOpen(false)
  }

  const tagColors = [
    "#FF5E5A", "#FFAF00", "#FFDE00", "#00E361", "#009CFF", "#D873FB", "#ACACB0"
  ]

  return (
    <Box borderRadius="16px" display="flex" flexDirection="column" gap="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? 'white' : '#111113'} padding="24px" width={["100%", "80%"]}>
      {saveIsLoading &&
        // {/* {true && */}
        <Box style={{ background: 'black', width: '100%', height: '100vh', zIndex: '99', position: 'absolute', top: 0, left: 0, opacity: '0.6', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress style={{ color: 'white', height: 'fit-content', width: '10%' }} />
        </Box>
      }
      <Box position="relative" display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="flex-start" position="relative" style={{ cursor: 'pointer' }} onClick={() => setProfileOpen((prev) => !prev)} sx={{ gap: '1rem' }}>
          {
            profileImage ? (
              <img src={profileImage} alt="Profile" style={{ width: '72px', height: '72px', borderRadius: "100%" }} />
            ) : (
              <SvgIcon component={ConetAvatar} inheritViewBox sx={{ fontSize: 72 }} />
            )
          }
          <Box position="absolute" bottom={0} left={50} width="24px" height="24px" display="flex" alignItems="center" justifyContent="center" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"} borderRadius="100%">
            <SvgIcon component={EditIcon} inheritViewBox sx={{ fontSize: 16 }} />
          </Box>
        </Box>
        {isProfileOpen ? (
          <ClickAwayListener onClickAway={() => setProfileOpen(false)}>
            <Box
              width={["150%", "310px"]}
              //height="359px"
              position="absolute"
              top="0vh"
              left={["-15vw", "80px"]}
              zIndex={9999}
              bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
              display="flex"
              flexDirection="column"
              borderRadius="16px"
              boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
              p="1rem"
              gap="32px"
            >
              <Stack justifyContent="space-between" direction="row">
                <Typography fontWeight="bold">
                  {t('settings.profilePic')}
                </Typography>
                <SvgIcon
                  cursor="pointer"
                  onClick={() => setProfileOpen(false)}
                  component={CloseIcon}
                  inheritViewBox
                  sx={{ fontSize: 16 }}
                />
              </Stack>
              <Box display="flex" justifyContent="center" alignItems="center">
                {profileImage ? (
                  <img src={profileImage} alt="Profile" style={{ width: '72px', height: '72px', borderRadius: "100%" }} />
                ) : (
                  <>
                    <SvgIcon component={ConetAvatar} inheritViewBox sx={{ fontSize: 72 }} />
                  </>
                )}
              </Box>
              <Stack justifyContent="space-evenly" direction="row">
                <Button variant="contained" onClick={handlePictureUpload} style={{ backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#577DFF" : "#8DA8FF", borderRadius: "16px", height: "52px", padding: "16px 32px 16px 32px" }}>
                  <Typography fontWeight="bold" color={localStorage.getItem('mui-mode') === 'light' ? "white" : "#262527"} fontSize="16px">
                    {t('settings.upload')}
                  </Typography>
                </Button>
                <Button variant="text" style={{ borderRadius: "16px", height: "52px", padding: "16px, 32px, 16px, 32px" }} onClick={() => removeImg()}>
                  <Typography fontWeight="bold" color={localStorage.getItem('mui-mode') === 'light' ? "red" : "#FFB4AB"} fontSize="16px">
                    {t('settings.cancel')}
                  </Typography>
                </Button>
              </Stack>
            </Box>
          </ClickAwayListener>
        ) : null}

        <Box
          position="absolute"
          top="0px"
          right="0px"
          display="flex"
          flexDirection="column"
          borderRadius="16px"
          p="1rem"
        >
          <Box onClick={() => setShowPrivateKeyModal(true)} display="flex" alignItems="center" color={localStorage.getItem('mui-mode') === 'light' ? "#577DFF" : "#8DA8FF"} style={{ cursor: 'pointer' }} gap="8px">
            <SvgIcon component={ContentCopyIcon} inheritViewBox sx={{ fontSize: 16 }} style={{ cursor: "pointer" }} />
            <Typography fontSize="16px" fontWeight={500} >
              {t('settings.copyPrivate')}
            </Typography>
            {showPrivateKeyModal && (
              <Box
                width={['90%', '340px']}
                position='absolute'
                bottom='5vh'
                right={['0', '15vw']}
                p='1rem'
                bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
                boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
                border={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"}
                borderRadius="16px"
                gap='8px'
              >
                <Box display='flex' justifyContent='space-between' width={'100%'}>
                  <Typography style={{ textTransform: 'uppercase', fontWeight: '700', fontSize: '16px', width: 'fit-content' }}>{t('settings.insertPassword')}</Typography>
                </Box>

                <Typography style={{ fontWeight: '400', fontSize: '14px', width: 'fit-content', marginTop: '16px' }}>{t('settings.insertPassword')}</Typography>
                <InputStyle style={{ borderRadius: '8px', padding: '4px' }}>
                  <InputBase
                    sx={{ width: '100%', flex: 1, fontSize: '1rem', marginTop: '8px', borderRadius: '8px' }}
                    id="outlined-password-input"
                    type={'password'}
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                  />
                </InputStyle>
                <ContainerButton onClick={() => { { handleCopyToClipboardPrivateFinal(); setShowPrivateKeyModal(false) } }}>
                  <ButtonLabel>{!copyIsLoading ? t('settings.copyPrivate') : <CircularProgress size={24} color="inherit" />} </ButtonLabel>
                </ContainerButton>
              </Box>

            )}
          </Box>
          {/* <Box height="56px" display="flex" alignItems="center" color={localStorage.getItem('mui-mode') === 'light' ? "red" : "#FFB4AB"} style={{ cursor: 'pointer' }} padding="16px" gap="8px">
            <SvgIcon component={VisibilityOffIcon} inheritViewBox sx={{ fontSize: 16 }} style={{ cursor: "pointer" }} />
            <Typography fontSize="16px" fontWeight="bold" >
            {t('settings.hideWallet')}
            </Typography>
          </Box> */}
        </Box>
      </Box>
      <Box display="flex" color="#8DA8FF" gap="8px" alignItems="center" style={{ cursor: 'pointer' }} onClick={handleCopyToClipboard}>
        <Typography fontSize="14px" fontWeight={500} >{shortId}</Typography>
        <SvgIcon component={ContentCopyIcon} inheritViewBox sx={{ fontSize: 16 }} />
      </Box>
      <Box display="flex" color="#8DA8FF" gap="8px" alignItems="center">
        <TextField id="outlined-read-only-input" value={inputText} fullWidth
          onChange={(e) => setInputText(e.target.value)} InputProps={{
            style: { fontSize: '16px', padding: '0px', borderRadius: '16px', maxWidth: '100%', maxHeight: '40px' }
          }}
        />
        <Button variant="contained" color="primary" disabled={saveIsLoading} onClick={() => handleSend()} style={{ padding: '8px 28px', borderRadius: '16px', fontWeight: '900', height: '40px', width: '100px', background: '#8DA8FF' }} >
          {saveIsLoading ? <CircularProgress style={{ height: '20px', width: '20px' }} /> : t('settings.save')}
        </Button>
      </Box>
      <Box display="flex" alignItems="center" position="relative">

        <Box width="139px" height="40px" display="flex" alignItems="center" justifyContent="space-between" padding="8px 16px 8px 16px" borderRadius="16px" border={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"} style={{ cursor: "pointer" }} onClick={() => setTagOpen((prev) => !prev)}>
          <Box display="flex" alignItems="center" gap="8px">
            <Typography variant="body2" fontSize="16px">
              {t('settings.tag')}
            </Typography>
            <Box width="16px" height="16px" borderRadius="100%" bgcolor={tagColor} />
          </Box>
          <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
        </Box>
        {isTagOpen ? (
          <ClickAwayListener onClickAway={() => setTagOpen(false)}>
            <Box
              width="280px"
              //height="359px"
              position="absolute"
              top="48px"
              left={0}
              zIndex={9999}
              bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
              display="flex"
              flexDirection="column"
              borderRadius="16px"
              boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
              p="1rem"
              gap="32px"
            >
              <Stack justifyContent="space-between" direction="row">
                <Typography fontWeight="bold">
                  {t('settings.tagAccount')}
                </Typography>
                <SvgIcon
                  cursor="pointer"
                  onClick={() => setTagOpen(false)}
                  component={CloseIcon}
                  inheritViewBox
                  sx={{ fontSize: 16 }}
                />
              </Stack>
              <Box display="flex" alignItems="center" gap="8px">
                {tagColors.map((color, index) => (
                  <Box display="flex" alignItems="center" justifyContent="center" key={index} width="20px" height="20px" borderRadius="100%" bgcolor={color} style={{ cursor: 'pointer' }} onClick={() => selectColorTag(color)}>
                    {tagColor === color ? (
                      <SvgIcon component={CheckIcon} inheritViewBox sx={{ fontSize: 18 }} />
                    ) : null}
                  </Box>
                ))}
                <SvgIcon component={DoDisturbIcon} inheritViewBox sx={{ fontSize: 20 }} style={{ cursor: 'pointer' }} onClick={() => setTagColor('')} />
              </Box>
            </Box>
          </ClickAwayListener>
        ) : null}

      </Box>
    </Box >
  );
};

export default InfoCard;
