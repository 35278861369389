import { useEffect, useState } from 'react';

interface AnimatedValueProps {
  value: number;
  decimalPlaces?: number;
  noCNTP?: boolean;
}

const AnimatedValue: React.FC<AnimatedValueProps> = ({ value, decimalPlaces = 5, noCNTP = false }) => {
  const [animatedValue, setAnimatedValue] = useState<number>(value);

  useEffect(() => {
    const duration = 800; // the lessen, the fastest
    const startTime = Date.now();
    const startValue = animatedValue && !isNaN(animatedValue) ? animatedValue : 0;
    const endValue = value && !isNaN(value) ? value : 0;

    const animateValue = () => {
      const elapsed = Date.now() - startTime;
      const progress = Math.min(elapsed / duration, 1);

      const currentValue = startValue + (endValue - startValue) * progress;
      setAnimatedValue(currentValue);

      if (progress < 1) {
        requestAnimationFrame(animateValue);
      }
    };

    animateValue();

  }, [value]);

  return (
    <>{(animatedValue || 0).toFixed?.(decimalPlaces)} {!noCNTP && "CNTP"}</>
  );
};

export default AnimatedValue;