import { Box, Stack, Typography } from '@mui/material';
import Chart from 'react-apexcharts';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useUserProfile } from '../../../../../../API/useUserProfile';
import { useRefreshStore } from '../../../../../../API/useRefreshStore';

export default function BalanceGraph() {
  const walletStore = useRefreshStore(state => state.wallets)

  const { userProfile, setUserProfile } = useUserProfile();
  const [totalCntpBalance, setTotalCntpBalance] = useState(0);
  const [totalGuardianBalance, setTotalGuardianBalance] = useState(0);
  const [totalConetianBalance, setTotalConetianBalance] = useState(0);

  function formatTimestampsToDays(timestamps: number[]): string[] {
    return timestamps.map((timestamp) => {
      const date = new Date(timestamp);
      const day = date.getDate().toString().padStart(2, "0"); // Ensure 2 digits
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensure 2 digits
      return `${day}/${month}`;
    });
  }

  const chartOptions: any = useMemo(() => {
    return {
      chart: {
        id: 'line-chart',
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: ['', '', '', '', ''],
      },
      tooltip: {
        theme: `${localStorage.getItem("mui-mode") === "light" ? "light" : "dark"}`,
      },
      stroke: {
        curve: 'smooth', // Smooth lines
      },
      yaxis: {
        labels: {
          formatter: function (value: any) {
            return ''
          }
        },
      },
      colors: ['#8DA8FF'], // Line color
    }
  }, [userProfile]);

  const chartSeries = useMemo(() => {
    if (!userProfile || !userProfile?.historicBalance)
      return [
        {
          name: 'Your Balance',
          data: [0, 0, 0, 0, 0],
        },
      ];

    return [
      {
        name: 'Your Balance',
        data: userProfile?.historicBalance.map((obj: any) => obj.balance) || [0, 0, 0, 0, 0],
      },
    ];
  }, []);

  return (
    <Stack gap="12px" width={['100%', '50%', '50%']}>
      <Stack direction="row" gap="20px" alignItems="flex-start">
        <Stack gap="4px" flex="1">
          <Typography color="#989899" fontSize="14px">Current Balance</Typography>
          <Typography fontSize="24px">{totalCntpBalance || Number("0").toFixed(6)} CNTP</Typography>
        </Stack>
        <Stack gap="4px">
          <Typography color="#989899" fontSize="14px">Your wallet</Typography>
          <Typography>{userProfile?.keyID?.substring(0, 8) + '...' + userProfile?.keyID?.substring(userProfile?.keyID?.length - 5)}</Typography>
        </Stack>
      </Stack>

      <Box bgcolor={localStorage.getItem("mui-mode") === "light" ? "#F0F0F2" : "#191919"} padding="0 24px 0 0" borderRadius="16px" height="189px">
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="line"
          height={'100%'}
        />
      </Box>

      <Stack direction="row" gap="24px" bgcolor={localStorage.getItem("mui-mode") === "light" ? "#F0F0F2" : "#191919"} padding="16px" borderRadius="16px">
        <Stack>
          <Typography fontSize="14px" color="#989899">Cloud nodes</Typography>
          <Typography>{totalGuardianBalance}</Typography>
        </Stack>
        <Stack>
          <Typography fontSize="14px" color="#989899">NFT</Typography>
          <Typography>{totalConetianBalance}</Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}