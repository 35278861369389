import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { gridBlockDefaultStyles, Item, networks, tokens } from '../common';
import { BiChevronDown } from 'react-icons/bi';
import { useMemo, useState } from 'react';
import { formatNumericPattern } from '../../../../../utilities/utilities';
import { SwapHoriz } from '@mui/icons-material';
import SelectModal from '../modal';

export default function Bridge() {
  const [selectedTokenId, setSelectedTokenId] = useState(1);
  const [selectedBalance, setSelectedBalance] = useState("0");
  const [selectedFromNetworkId, setSelectedFromNetworkId] = useState(1);
  const [selectedToNetworkId, setSelectedToNetworkId] = useState(2);

  const [fieldMouseOver, setFieldMouseOver] = useState(false);
  const [fieldFocused, setIsFieldFocused] = useState(false);

  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
  const [isFromNetworkModalOpen, setIsFromNetworkModalOpen] = useState(false);
  const [isToNetworkModalOpen, setIsToNetworkModalOpen] = useState(false);

  const theme = useTheme();

  const selectedToken = useMemo(() => tokens.find((token) => token.id === selectedTokenId)!, [selectedTokenId]);

  const selectedFromNetwork = useMemo(() => networks.find((network) => network.id === selectedFromNetworkId)!, [selectedFromNetworkId]);
  const selectedToNetwork = useMemo(() => networks.find((network) => network.id === selectedToNetworkId)!, [selectedToNetworkId]);

  function setMaxBalance() {
    // Set max current token balance on selectedBalance
  }

  function swapNetworks() {
    const firstNetwork = selectedFromNetworkId;

    setSelectedFromNetworkId(selectedToNetworkId);
    setSelectedToNetworkId(firstNetwork);
  }

  return (
    <>
      <Stack padding={'0 16px'} minWidth={['0', '656px']} width={'100%'} alignItems={'center'} justifyContent={'center'}>
        <Stack
          marginTop="16px"
          marginBottom="8px"
          padding="16px"
          width="100%"
          maxWidth="656px"
          borderRadius="16px"
          bgcolor={theme.palette.mode === "light" ? "#F3F0F2" : "#1B1B1B"}
          color={theme.palette.mode === "light" ? "black" : "white"}
        >
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(9, 1fr)', gridTemplateRows: 'repeat(7, 1fr)', gap: '8px' }}>
            <Box sx={{ gridRow: '1 / 3', gridColumn: '1 / 10', ...gridBlockDefaultStyles, alignItems: 'center', gap: '20px' }}>
              <Typography color="#989899" fontSize="14px" alignSelf="flex-start">Token</Typography>
              <button className="button-ptn" style={{ width: "100%", justifyContent: "space-between" }} onClick={() => setIsTokenModalOpen(true)}>
                <Stack direction="row" alignItems="center" gap="8px">
                  <img src={selectedToken.icon} alt={selectedToken.name} width={32} height={32} />
                  <Typography color="#FFF">{selectedToken.name}</Typography>
                </Stack>
                <BiChevronDown color="#FFF" />
              </button>
            </Box>
            <Box
              onMouseOver={() => setFieldMouseOver(true)}
              onMouseOut={() => setFieldMouseOver(false)}
              sx={{
                gridRow: '3 / 5', gridColumn: '1 / 10', ...gridBlockDefaultStyles,
                flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '20px',
                background: (fieldMouseOver && !fieldFocused) ? theme.palette.mode === "light" ? "#F0F0F2" : "#262626" : theme.palette.mode === "light" ? "#F8F8FA" : "#0D0D0D",
                border: `1px solid ${fieldFocused ? theme.palette.mode === "light" ? "#DCDCDC" : "#4C4C4C" : fieldMouseOver ? theme.palette.mode === "light" ? "F0F0F2" :  "#262626" : theme.palette.mode === "light" ? "#F0F0F2" : "#0D0D0D"}`
              }}
            >
              <Stack gap="8px">
                <Typography color="#989899" fontSize="14px">Sell</Typography>
                <input
                  onFocus={() => setIsFieldFocused(true)}
                  onBlur={() => setIsFieldFocused(false)}
                  value={selectedBalance}
                  onChange={(e) => setSelectedBalance(formatNumericPattern(e.target.value))}
                  style={{ fontSize: '60px', width: '100%', color: '#FFF', background: 'transparent', border: 0 }}
                />
              </Stack>
              <Stack direction="row" gap="16px">
                <Stack>
                  <Typography>Balance</Typography>
                  <Typography fontWeight="700">1000</Typography>
                </Stack>
                <button onClick={setMaxBalance} style={{ padding: "8px 16px", background: "#79F8FF0D", border: "0", borderRadius: "8px", color: "#79F8FF" }}>MAX</button>
              </Stack>
            </Box>
            <Box sx={{ gridRow: '5 / 7', gridColumn: '1 / 5', ...gridBlockDefaultStyles, alignItems: 'center', gap: '20px' }}>
              <Typography color="#989899" fontSize="14px" alignSelf="flex-start">From</Typography>
              <button className="button-ptn" style={{ width: "100%", justifyContent: "space-between" }} onClick={() => setIsFromNetworkModalOpen(true)}>
                <Stack direction="row" alignItems="center" gap="8px">
                  <img src={selectedFromNetwork.icon} alt={selectedFromNetwork.name} width={32} height={32} />
                  <Typography color="#FFF">{selectedFromNetwork.name}</Typography>
                </Stack>
                <BiChevronDown color="#FFF" />
              </button>
            </Box>
            <button
              onClick={swapNetworks}
              style={{ ...(gridBlockDefaultStyles as any), border: 'none', gridRow: '5 / 7', gridColumn: '5 / 6', justifyContent: 'center', alignItems: 'center', }}
            >
              <SwapHoriz sx={{ color: '#FFF', fontSize: '30px' }} />
            </button>
            <Box sx={{ gridRow: '5 / 7', gridColumn: '6 / 10', ...gridBlockDefaultStyles, alignItems: 'center', gap: '20px' }}>
              <Typography color="#989899" fontSize="14px" alignSelf="flex-start">To</Typography>
              <button className="button-ptn" style={{ width: "100%", justifyContent: "space-between" }} onClick={() => setIsToNetworkModalOpen(true)}>
                <Stack direction="row" alignItems="center" gap="8px">
                  <img src={selectedToNetwork.icon} alt={selectedToNetwork.name} width={32} height={32} />
                  <Typography color="#FFF">{selectedToNetwork.name}</Typography>
                </Stack>
                <BiChevronDown color="#FFF" />
              </button>
            </Box>
            <Button sx={{ gridRow: '7 / 8', gridColumn: '1 / 10', ...gridBlockDefaultStyles, background: "#79F8FF1A", color: "#79F8FF", alignItems: "center"  }}>
              <Typography fontWeight="700">Bridge</Typography>
            </Button>
          </Box>
        </Stack>
        <Stack width="100%" maxWidth="656px">
          <Typography alignSelf="flex-end" color="#676768">Gas Cost <strong>$5</strong></Typography>
        </Stack>
      </Stack>
      <SelectModal
        title="Select a token"
        name="Tokens"
        searchPlaceholder="Search a token here..."
        items={tokens}
        handleItemSelect={(item: Item) => setSelectedTokenId(item.id)}
        open={isTokenModalOpen}
        onClose={() => setIsTokenModalOpen(false)}
      />
      <SelectModal
        title="Select a network"
        name="Networks"
        searchPlaceholder="Search a network here..."
        items={networks}
        handleItemSelect={(item: Item) => setSelectedFromNetworkId(item.id)}
        open={isFromNetworkModalOpen}
        onClose={() => setIsFromNetworkModalOpen(false)}
      />
      <SelectModal
        title="Select a network"
        name="Networks"
        searchPlaceholder="Search a network here..."
        items={networks}
        handleItemSelect={(item: Item) => setSelectedToNetworkId(item.id)}
        open={isToNetworkModalOpen}
        onClose={() => setIsToNetworkModalOpen(false)}
      />
    </>
  )
}