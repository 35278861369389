import { Box, SvgIcon, useTheme } from '@mui/material';
import { Chat } from './types';
import { formatIpAddr } from '../../../../utilities/formatIpAddr';
import { FaCheckDouble, FaSearch } from 'react-icons/fa';
import { ProfileData } from '../../../../store/appState/appStateReducer';
import { ReactComponent as ConetAvatar } from "../../../../assets/images/con-avatar.svg";
import { timeAgo } from '../../../../utilities/timeAgo';
import { motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ContactsProps {
  chats: Chat[];
  chooseChat: (chat: Chat) => void;
  choosenChatId?: number;
  profile: ProfileData | null;
  isOpen: boolean;
  isMobile: boolean;
}

export default function Contacts({ chats, chooseChat, choosenChatId, profile, isOpen, isMobile }: ContactsProps) {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { t } = useTranslation();

  const theme = useTheme();

  const motionDivProps = useMemo(() => !isMobile ? ({}) : ({
    variants: {
      open: { width: "auto", padding: "1rem", borderRightWidth: "1px", },
      closed: { width: 0, padding: 0, borderRightWidth: 0, },
    },
    initial: isOpen ? "open" : "closed",
    animate: isOpen ? "open" : "closed",
    transition: { duration: 0.3, ease: "easeInOut" },
  }), [isMobile, isOpen]);

  function handleChooseChat(chat: Chat) {
    chooseChat(chat);
    setSearchTerm('');
  }

  return (
    <motion.div
      className={`contacts-wrapper border ${theme.palette.mode === "dark" ? "dark-block" : "light-block"}`}
      {...motionDivProps}
    >
      <Box className="chat-finder">
        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h2>{t("chat.chats")}</h2>
        </Box>
        <Box className={`chat-finder-input ${theme.palette.mode === "dark" ? "dark-block-grey" : "light-block-grey"}`}>
          <FaSearch />
          <input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder={t("chat.searchPlaceholder")} />
        </Box>
      </Box>
      <p style={{ fontWeight: 700, margin: ".6rem 0" }}>{t("chat.recent")}</p>
      {
        chats.filter((chat) => chat.user.includes(searchTerm.toLowerCase())).map((chat) => {
          const lastMessage = chat.messages[chat.messages.length - 1];

          return (
            <button
              onClick={() => handleChooseChat(chat)}
              className={`contact ${chat.id === choosenChatId ? 'current' : ''}`}
            >
              <SvgIcon
                component={ConetAvatar}
                inheritViewBox
                sx={{ fontSize: 32 }}
              />
              <div>
                <p>{formatIpAddr(chat.user)}</p>
                <div style={{ display: "flex", gap: ".4rem", marginTop: "1rem", opacity: .6 }}>
                  {profile?.keyID === lastMessage.sentBy && (<FaCheckDouble size={14} />)}
                  <p className="contact-message" style={{ fontSize: "14px", }}>{lastMessage.content}</p>
                </div>
                <p style={{ position: "absolute", top: "1.2rem", right: ".8rem" }}>{timeAgo(lastMessage.date.toLocaleString())}</p>
              </div>
            </button>
          )
        })
      }
    </motion.div>
  )
}