import { ClickAwayListener, Container, SvgIcon, Typography } from "@mui/material"
import { ButtonLabel, ContainerButton, LinkText, SpanTitle } from "./styles"
import { useEffect, useMemo, useState } from "react"
import useAppState from "../../../../store/appState/useAppState"
import { useIntl } from "react-intl"
import UnLockWallet from "./UnLockWallet"
import CreateWallet from "./CreateWallet"
// import Conet_Logo_Dark from "../../../../assets/images/conet-logo-dark.png"
import Conet_Logo_Light from "../../../../assets/logo/Logo_Blue_TransparentBG.png"
import Conet_Logo_Dark from "../../../../assets/Logo_White_TransparentBG.png";
import { useColorScheme, styled } from "@mui/material-next/styles"
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import bgLogoDark from '../../../../assets/logo/Logo_Blue_TransparentBG-dark.png'
import CopyYourSecretPhrase from "./CopyYourSecretPhrase"
import WriteYourSecretPhrase from "./WriteYourSecretPhrase"
import { listeningVersionHook, platform, type_platformStatus } from "../../../../API/platform"
import { useTypedSelector } from "../../../../store/store"
import { setTimeout } from "timers/promises"
import { useTranslation } from "react-i18next"
import LanguageSwitcher from "./LanguageSwitcher"

interface WelcomeToConetProps {
    onClickStep: (number: number) => void
}
export const LogoLight = styled(Box)(({ theme }) => ({
    width: '48px',
    height: '48px',
    backgroundImage: `url(${Conet_Logo_Light})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
}))

export const LogoDark = styled(Box)(({ theme }) => ({
    width: '48px',
    height: '48px',
    backgroundImage: `url(${Conet_Logo_Dark})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
}))

export const WelcomeToConet = ({ onClickStep }: WelcomeToConetProps) => {
    const { mode } = useColorScheme()
    const [conetPlatform, setConetPlatform] = useState<type_platformStatus>('')
    const [workerLoading, setWorkerLoading] = useState(0)
    const conet_platform = new platform(setConetPlatform, setWorkerLoading)
    const [profileVer, setProfileVer] = useState<number>(0)

    const [listSrp, setListSrp] = useState<string[]>([])
    const [step, setStep] = useState(0)
    const LogoBG = styled(Box)(({ theme }) => ({
        width: '100%',
        height: '100vhx',
        backgroundImage: `url(${Conet_Logo_Dark})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }))

    const LogoLight = styled(Box)(({ theme }) => ({
        width: '48px',
        height: '48px',
        backgroundImage: `url(${Conet_Logo_Light})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }))

    const hasContainer = useTypedSelector(state => state.appState.hasContainer)

    const { t } = useTranslation()

    useEffect(() => {
        if (hasContainer) {
            setStep(2)
        }
        listeningVersionHook(setProfileVer)
    }, [])

    const StepPage = () => {

        switch (step) {
            case 0:
                return <Container
                    maxWidth='lg'
                    sx={{
                        height: '100vh',
                        width: '100%',
                        overflowX: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: { xs: '0' },
                        backgroundImage: ` url(${bgLogoDark})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}>
                    {/* <img
                        src={bgLogoDark}
                        style={{ position: `absolute`, top: 0, zIndex: 1, opacity: '0.5' }}
                    /> */}
                    <Container sx={{ zIndex: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', height: '100vh', padding: { xs: '0', md: '1rem' } }}>
                        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {localStorage.getItem('mui-mode') == 'dark' ? <LogoDark /> : <LogoLight />}
                            <Typography style={{ textTransform: 'uppercase', fontWeight: '700', fontSize: '14px' }}>{t('welcome.create')}</Typography>
                            <Typography
                                sx={{
                                    fontSize: { xs: '57px', md: '88px' },
                                    fontWeight: { xs: '400', md: '600' },
                                    marginTop: '50px',
                                    lineHeight: { xs: '64px', md: '100%' }
                                }}
                            >
                                {t('welcome.titleWhite')} <SpanTitle>{t('welcome.titleBlue')}</SpanTitle>
                            </Typography>
                        </Container>
                        <Stack justifyContent="flex-end" direction="column" alignItems="center" sx={{ paddingBottom: '6rem', width: '100%' }}>
                            <Container maxWidth='xs'>
                                <ContainerButton onClick={() => setStep(1)}>
                                    <ButtonLabel>{t('welcome.createButton')}</ButtonLabel>
                                </ContainerButton>

                                <LinkText onClick={() => setStep(4)} style={{ cursor: 'pointer', textAlign: "center" }}>{t('welcome.recover')}</LinkText>

                                <Box marginTop={'24px'}>
                                    <LanguageSwitcher />
                                </Box>
                            </Container>
                        </Stack>
                    </Container>
                </Container>
            case 1:
                return <CreateWallet onClickStep={(event) => setStep(event)} createAccount={conet_platform.createAccount} setListSrp={setListSrp} />
            case 2:
                return <UnLockWallet onClickStep={(event) => setStep(event)} setProfileVer={setProfileVer} testPasscode={conet_platform.testPasscode} getAllProfiles={conet_platform.getAllProfiles} importWallet={conet_platform.importWallet} updateProfile={conet_platform.updateProfile} listSrp={listSrp} />
            case 3:
                return <CopyYourSecretPhrase onClickStep={(event) => setStep(event)} showSRP={listSrp} />
            case 4:
                return <WriteYourSecretPhrase onClickStep={(event) => setStep(event)} recoverAccount={conet_platform.recoverAccount} />
        }
    }
    return StepPage()

}
