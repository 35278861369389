export const recoverWalletEn = {
  title: "Recover your Wallet",
  subTitleWhite: "Write or paste your phrase to reset your",
  subTitleBlue: " password",
  caption: "Make sure you’re using the correct Secret Recovery Phrase before proceeding. You will not be able to undo this.",
  newPassword: "New password",
  confirmPassword: "Confirm password",
  resetPassword: "Reset password",
  passwordMismatch: "Password and confirmation do not match",
  passwordTooShort: "Password needs to be at least 6 characters long",
};

export const recoverWalletZh = {
  title: "恢复您的钱包",
  subTitleWhite: "输入或粘贴您的助记词以",
  subTitleBlue: "重置密码",
  caption: "请确保使用正确的助记词进行密码重置, 此操作不 可取消",
  newPassword: "新密码",
  confirmPassword: "确认密码",
  resetPassword: "重置密码",
};
