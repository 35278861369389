import { useIntl } from "react-intl";
import { Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material-next";
import { useTheme } from "styled-components";
import { IoIosArrowForward } from "react-icons/io";

import { getWorkerService } from "../../../../services/workerService/workerService";
import { SuperNodeBanner } from "./pags/SuperNodeBanner";
import DashBoardpanel from "./pags/DashBoardpanel";
import { BoostBanner } from "./pags/BoostBanner";
import { Community } from "./pags/Community";
import {
  ContentWrapper,
  InternalHeader,
  InternalHeaderWelcome,
} from "./styles/index";
import useAppState from "../../../../store/appState/useAppState";
import { setScrollToSuperNodeBanner } from "../../../../store/appState/appStateActions";
import store from "../../../../store/store";
import { useTranslation } from "react-i18next";
import WalletPool from './pags/WalletPool';
import { MiningToolsPromoting } from "./pags/MiningToolsPromoting";
import { useRefreshStore } from "../../../../API/useRefreshStore";

const currentProfile = () => {
  const workerService = getWorkerService();
  if (workerService.data.status === "LOCKED") {
    return null;
  }
  // const index = workerService.data.profiles.findIndex((n: any) => {
  //   return n.isPrimary;
  // });

  return workerService.data;
};

const Miner = () => {
  const intl = useIntl();
  const theme = useTheme();
  const SuperNodeBannerRef = useRef<any>(null);
  const [counter, setCounter] = useState<number>(0)

  const [showNodeMiner, setShowNodeMiner] = useState(true);
  const [cntp, setCntp] = useState("0");
  const [todayCntp, setTodayCntp] = useState("0");
  const { scrollToSuperNodeBanner } = useAppState();
  const { dAPPOpen } = useAppState();

  useEffect(() => {
    if (scrollToSuperNodeBanner) {
      SuperNodeBannerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    return () => {
      store.dispatch(setScrollToSuperNodeBanner(false));
    }
  }, []);

  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = () => {
      if (!active) {
        return;
      }
      const profile = currentProfile();
      setCntp(profile.tokens?.CNTP?.balance);
    };

    let active = true;
    fetchData();
    return () => {
      active = false;
    };
  }, []);

  /*   useEffect(() => {
      const element = document.getElementById(`welcome`);
      if (!element) return;

      element.innerHTML = element.innerHTML.concat(
        `<span style="color: ${theme.ui.colors.primary}; font-weight: 600;">${t('dashboard.titleBlue')}</span>`
      );
    }, []); */


  const activateAdminMonitor = () => {
    setCounter(counter + 1)

    if (counter > 5) {
      useRefreshStore.getState().setShowAdminMonitor(true)
      localStorage.setItem("showAdminMonitor", JSON.stringify(true));
    }
  }

  return (
    <ContentWrapper>
      <InternalHeader width="100%">
        <InternalHeaderWelcome>
          <div onClick={activateAdminMonitor}>
            <Typography
              id="welcome"
              variant="h4"
              sx={{ fontWeight: "500", fontSize: `2.5rem` }}
            >
              {t('dashboard.titleWhite')}
            </Typography>

            <Typography
              id="welcome"
              variant="h4"
              sx={{ fontWeight: "500", fontSize: `2.5rem`, color: theme.ui.colors.primary }}
            >
              {t('dashboard.titleBlue')}
            </Typography>
          </div>


          <Link
            target="_blank"
            href={
              "https://scan.conet.network/address/0xa4b389994A591735332A67f3561D60ce96409347"
            }
          >
            <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-end">

              <Typography
                sx={{
                  color: theme.ui.colors.primary,
                  fontWeight: `500`,
                  fontSize: `14px`,
                }}
              >
                {t('dashboard.smart')}
              </Typography>
              <IoIosArrowForward fontSize="14px" />
            </Stack>
          </Link>
        </InternalHeaderWelcome>
        <DashBoardpanel />
        <MiningToolsPromoting />
        <WalletPool />
      </InternalHeader>

      <Grid
        item
        md={12}
        sm={8}
        sx={{ textAlign: "center", width: "100%", padding: "5rem 0 3rem 0" }}
      >
        <Community
          CNTP={todayCntp}
          setCNTP={setCntp}
          setTodayCNTP={setTodayCntp}
        />
      </Grid>

      {!showNodeMiner && (
        <Grid
          item
          md={12}
          sm={8}
          xs={4}
          sx={{ textAlign: "center", width: "100%", padding: "5rem 0 3rem 0" }}
        >
          <Button
            variant="elevated"
            onClick={() => setShowNodeMiner(true)}
            sx={{ fontFamily: "inherit" }}
          >
            {intl.formatMessage({ id: "platform.miner.register.title" })}
          </Button>
        </Grid>
      )}

      <SuperNodeBanner ref={SuperNodeBannerRef} />
      <BoostBanner />

    </ContentWrapper>
  );
};

export default Miner;
