export type Message = any;
export type Chat = any;

export const chatsMock = [
  {
    id: 1,
    user: "0x79cab9a87c8df9a86d8a9f689ca896ba896d89f7",
    messages: [
      {
        id: 1,
        sentBy: "0x79cab9a87c8df9a86d8a9f689ca896ba896d89f7",
        content: "Hey, what's up?",
        date: new Date(Date.now() - 3 * 24 * 60 * 60 * 1000), // 3 days ago
      },
      {
        id: 2,
        sentBy: "0x004ca163ff86d72ed41e49c82f5c0dd0b53996c2",
        content: "Not much, just chilling. You?",
        date: new Date(Date.now() - 3 * 24 * 60 * 60 * 1000), // 3 days ago
      },
      {
        id: 3,
        sentBy: "0x79cab9a87c8df9a86d8a9f689ca896ba896d89f7",
        content: "Same here. Just taking it easy.",
        date: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000), // 2 days ago
      },
      {
        id: 4,
        sentBy: "0x004ca163ff86d72ed41e49c82f5c0dd0b53996c2",
        content: "What have you been up to lately?",
        date: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000), // 2 days ago
      },
      {
        id: 5,
        sentBy: "0x79cab9a87c8df9a86d8a9f689ca896ba896d89f7",
        content: "Just working and hanging out. How about you?",
        date: new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day ago
      }
    ],
  },
  {
    id: 2,
    user: "0x123fd9a1b8df7c2a87f5d8c8b3f7a78d64a87d1a",
    messages: [
      {
        id: 1,
        sentBy: "0x123fd9a1b8df7c2a87f5d8c8b3f7a78d64a87d1a",
        content: "You free later?",
        date: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000), // 6 days ago
      },
      {
        id: 2,
        sentBy: "0x004ca163ff86d72ed41e49c82f5c0dd0b53996c2",
        content: "I should be, what's up?",
        date: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000), // 6 days ago
      },
      {
        id: 3,
        sentBy: "0x123fd9a1b8df7c2a87f5d8c8b3f7a78d64a87d1a",
        content: "Just wanted to catch up.",
        date: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), // 1 day ago
      },
      {
        id: 4,
        sentBy: "0x004ca163ff86d72ed41e49c82f5c0dd0b53996c2",
        content: "Sure, I’m available in the evening.",
        date: new Date(Date.now() - 2 * 60 * 60 * 1000), // 2 hours ago
      },
      {
        id: 5,
        sentBy: "0x123fd9a1b8df7c2a87f5d8c8b3f7a78d64a87d1a",
        content: "Sounds good, I’ll message you then.",
        date: new Date(Date.now() - 2 * 60 * 60 * 1000), // 2 hours ago
      }
    ],
  },
  {
    id: 3,
    user: "0xc78fa9b7e6a3d4b8c9d8b2a4c6f8a7c7d8f9b3a1",
    messages: [
      {
        id: 1,
        sentBy: "0xc78fa9b7e6a3d4b8c9d8b2a4c6f8a7c7d8f9b3a1",
        content: "Did you review the document?",
        date: new Date(Date.now() - 8 * 24 * 60 * 60 * 1000), // 8 days ago
      },
      {
        id: 2,
        sentBy: "0x004ca163ff86d72ed41e49c82f5c0dd0b53996c2",
        content: "Yeah, it looks good. Just a few tweaks.",
        date: new Date(Date.now() - 8 * 24 * 60 * 60 * 1000), // 8 days ago
      },
      {
        id: 3,
        sentBy: "0xc78fa9b7e6a3d4b8c9d8b2a4c6f8a7c7d8f9b3a1",
        content: "Great, I’ll finalize it today.",
        date: new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day ago
      },
      {
        id: 4,
        sentBy: "0x004ca163ff86d72ed41e49c82f5c0dd0b53996c2",
        content: "Awesome, let me know if anything comes up.",
        date: new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day ago
      }
    ],
  },
  {
    id: 4,
    user: "0x9a87b6d1f4e5a3d8b9c7a5d4e6c7a2b9d4c3a6f7",
    messages: [
      {
        id: 1,
        sentBy: "0x004ca163ff86d72ed41e49c82f5c0dd0b53996c2",
        content: "Are you heading to the meeting later?",
        date: new Date(Date.now() - 12 * 24 * 60 * 60 * 1000), // 12 days ago
      },
      {
        id: 2,
        sentBy: "0x9a87b6d1f4e5a3d8b9c7a5d4e6c7a2b9d4c3a6f7",
        content: "Yeah, I’ll be there in a bit.",
        date: new Date(Date.now() - 12 * 24 * 60 * 60 * 1000), // 12 days ago
      },
      {
        id: 3,
        sentBy: "0x004ca163ff86d72ed41e49c82f5c0dd0b53996c2",
        content: "Cool, see you there.",
        date: new Date(Date.now() - 6 * 60 * 60 * 1000), // 6 hours ago
      },
      {
        id: 4,
        sentBy: "0x9a87b6d1f4e5a3d8b9c7a5d4e6c7a2b9d4c3a6f7",
        content: "On my way now.",
        date: new Date(Date.now() - 5 * 60 * 60 * 1000), // 5 hours ago
      },
      {
        id: 5,
        sentBy: "0x004ca163ff86d72ed41e49c82f5c0dd0b53996c2",
        content: "Great, see you soon.",
        date: new Date(Date.now() - 5 * 60 * 60 * 1000), // 5 hours ago
      }
    ],
  }
];




