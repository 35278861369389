import { useState } from 'react';
import Swap from './swap';
import { Stack, Typography } from '@mui/material';
import Transfer from './transfer';
import Bridge from './bridge';
import "./styles.css";

export default function SwapFeatures() {
  const [operationType, setOperationType] = useState<'bridge' | 'swap' | 'transfer'>('transfer');

  return (
    <Stack width="100%" alignItems="center" padding="22px">
      <Typography fontSize="36px" alignSelf="flex-start">Asset Management</Typography>
      <Stack gap="8px" paddingTop="20px" width="100%">
        <Stack direction="row" gap="16px" justifyContent="center" >
          <button style={{ all: "unset", cursor: "not-allowed", color: "#989898", fontWeight: operationType === "bridge" ? "700" : "400" }} onClick={() => setOperationType("bridge")} disabled>Bridge</button>
          <button style={{ all: "unset", cursor: "not-allowed", color: "#989898", fontWeight: operationType === "swap" ? "700" : "400" }} onClick={() => setOperationType("swap")} disabled>Swap</button>
          <button style={{ all: "unset", cursor: "pointer", fontWeight: operationType === "transfer" ? "700" : "400" }} onClick={() => setOperationType("transfer")}>Transfer</button>
        </Stack>
        {
          operationType === "bridge" && <Bridge />
        }
        {
          operationType === "swap" && <Swap />
        }
        {
          operationType === "transfer" && <Transfer />
        }
      </Stack>
    </Stack>
  )
}