import { Box, Stack, Typography, SvgIcon, useTheme } from '@mui/material';
import { motion } from "framer-motion";
import { ReactComponent as arrowCircleUp } from "../../assets/arrow_circle_up.svg";
import { ReactComponent as arrowCircleDown } from "../../assets/arrow_circle_down.svg";
import { ReactComponent as swapCircle } from "../../assets/swap_circle.svg";
import spaceBackground from "../../assets/space-background.png";
import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './styles.css';


// import required modules
import { Pagination } from 'swiper/modules';


const quickActions = [
  {
    name: "Deposit",
    icon: arrowCircleDown,
  },
  {
    name: "Swap",
    icon: swapCircle,
  },
  {
    name: "Transfer",
    icon: arrowCircleUp,
  },
  {
    name: "Wallet",
    icon: arrowCircleUp,
  },
  {
    name: "dApps",
    icon: arrowCircleUp,
  },
  {
    name: "dApps",
    icon: arrowCircleUp,
  },
]

export default function QuickActions() {
  const theme = useTheme();

  return (
    <Stack gap="16px" width={['100%', '50%', '50%']} sx={{ paddingBottom: '10px' }}>
      <Typography color="#FFFFFF">Quick actions</Typography>
      <Box display="grid" gridTemplateRows="repeat(2, 1fr)" gridTemplateColumns="repeat(3, 1fr)" gap="8px">
        {
          quickActions.map((action, i) => (
            <Box bgcolor={localStorage.getItem("mui-mode") === "light" ? "#F0F0F2" : "#191919"} borderRadius="16px">
              <motion.div
                className="box"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                whileHover={{ scale: [null, 1.07, 1.06] }}
                transition={{
                  duration: 0.8,
                  delay: i * 0.05,
                  ease: [0, 0.71, 0.2, 1.01]
                }}
              >
                <Stack padding="24px" alignItems="center" gap="16px" borderRadius="16px" sx={{ cursor: 'pointer' }}>
                  <SvgIcon
                    sx={{ color: `${localStorage.getItem("mui-mode") === "light" ? "#8DA8FF" : "#FFFFFF"}` }}
                    viewBox="0 0 32 32" component={action.icon} alt={action.name} width={32} height={32} />
                  <Typography fontSize="14px">{action.name}</Typography>
                </Stack>
              </motion.div>
            </Box>
          ))
        }
      </Box>

      <Swiper
        pagination={{
          clickable: true,
          renderBullet: (index, className) => (
            `<span class="${className}" style="background-color: #8DA8FF;"></span>`
          )
        }}
        modules={[Pagination]} className="mySwiper" style={{ width: '100%' }}>
        <SwiperSlide>
          <Stack sx={{
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
            backgroundImage: `url(${spaceBackground})`,
            backgroundColor: 'lightgray',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            cursor: 'pointer'
          }} padding="16px" gap="24px">
            <Typography fontSize="14px" color="#FFFFFF">The CoNETian Mining Game</Typography>
            <Typography color={theme.palette.mode === "light" ? "#FFFFFF" : "#858586"}>Play and earn rewards by completing tasks directly on Telegram.</Typography>
          </Stack>
        </SwiperSlide>

        <SwiperSlide>
          <Stack sx={{
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
            backgroundImage: `url(${spaceBackground})`,
            backgroundColor: 'lightgray',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            cursor: 'pointer'
          }} padding="16px" gap="24px">
            <Typography fontSize="14px" color="#FFFFFF">The CoNETian Mining Game</Typography>
            <Typography color={theme.palette.mode === "light" ? "#FFFFFF" : "#858586"}>Play and earn rewards by completing tasks directly on Telegram.</Typography>
          </Stack>
        </SwiperSlide>

        <SwiperSlide>
          <Stack sx={{
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
            backgroundImage: `url(${spaceBackground})`,
            backgroundColor: 'lightgray',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            cursor: 'pointer'
          }} padding="16px" gap="24px">
            <Typography fontSize="14px" color="#FFFFFF">The CoNETian Mining Game</Typography>
            <Typography color={theme.palette.mode === "light" ? "#FFFFFF" : "#858586"}>Play and earn rewards by completing tasks directly on Telegram.</Typography>
          </Stack>
        </SwiperSlide>
      </Swiper>

    </Stack>
  )
}