/* eslint-disable */

import { Box, CircularProgress, Container, IconButton, InputBase, Paper, Skeleton, Stack, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { useContext, useState } from 'react';
import { styled as materialStyled } from '@mui/material'

import { ReactComponent as defaultProfilePicture } from '../profileTEST/assets/default_profile.svg';

import { ReactComponent as closeIcon } from './assets/close.svg';

import { Button, ClickAwayListener } from '@mui/base';
import { BiPlus } from 'react-icons/bi';
import { useRefreshStore } from '../../../../API/useRefreshStore';
import { ButtonLabel, ContainerButton, ContainerTransparentButton } from '../dashboard/styles';
import { platform } from '../../../../API/platform';
import { toast } from 'react-toastify';
import { t } from 'i18next';

const InputStyle = materialStyled(Paper)(() => ({
  display: 'flex',
  textAlign: 'center',
  borderRadius: '1rem',
  border: '1px solid #E4E2E4',
  maxWidth: '380px',
  width: '100%',
  height: '52px',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  justifyContent: 'center'
}))

export default function WalletPool() {
  const monitoredWallets = useRefreshStore(state => state.monitoredWallets)

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const [isAddMonitWalletVisible, setIsAddMonitWalletVisible] = useState(false);
  const [isAddingWallet, setIsAddingWallet] = useState<boolean>(false);
  const [walletBeingRemoved, setWalletBeingRemoved] = useState<string>('');
  const [newWalletAddres, setNewWalletAddress] = useState<string>("");
  const [walletAddError, setWalletAddError] = useState<string>("");

  //CONET gateway functions
  const [conetPlatform, setConetPlatform] = useState<any>('')
  const [workerLoading, setWorkerLoading] = useState(0)
  const conet_platform = new platform(setConetPlatform, setWorkerLoading)

  const addNewWallet = async () => {
    setIsAddingWallet(true)

    try {
      const isWalletAddressValid = await conet_platform.isAddress(newWalletAddres)

      if (isWalletAddressValid.length > 0 && isWalletAddressValid[0] === true) {
        await conet_platform.addMonitoredWallet(newWalletAddres)

        setTimeout(() => {
          setNewWalletAddress('')
          setIsAddingWallet(false)
          setWalletBeingRemoved('')

          toast.success("Address added to monitored wallets", {
            position: "bottom-center",
            autoClose: 5000,
          });
        }, 6000)
      } else {
        setWalletAddError("Please enter a valid wallet address")
      }
    } catch (ex) {
      setNewWalletAddress('')
      setIsAddingWallet(false)
      setWalletBeingRemoved('')
      return
    }
  }

  const removeMonitoredWallet = async (walletAddress: string) => {
    setWalletBeingRemoved(walletAddress)

    try {
      await conet_platform.removeMonitoredWallet(walletAddress)

      setTimeout(() => {
        setWalletBeingRemoved('')

        toast.success("Address removed from monitored wallets", {
          position: "bottom-center",
          autoClose: 5000,
        });
      }, 6000)
    } catch (ex) {
      setWalletBeingRemoved('')
      return
    }
  }

  const handleCloseAddMonitWallet = () => {
    setIsAddMonitWalletVisible(false);
  };

  const handleOpenAddMonitWallet = () => {
    setIsAddingWallet(false)
    setNewWalletAddress('')
    setWalletAddError('')
    setIsAddMonitWalletVisible(true);
  };

  return (
    <Stack direction="column" gap="10px" padding="44px 16px">
      {isAddMonitWalletVisible ? (
        <ClickAwayListener onClickAway={handleCloseAddMonitWallet}>
          <Box
            width={['90%', '440px']}
            position='absolute'
            top='30vh'
            left={['5vw', '35vw']}
            zIndex={9999}
            bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
            display="flex"
            flexDirection="column"
            borderRadius="16px"
            boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
            p="2rem"
            gap="32px"
          >
            <Stack direction="column" gap="40px">
              <Container sx={{ padding: '0 !important', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                <Typography style={{ fontSize: '16px', fontWeight: '400' }}> {t('dashboard.miningPool.enterAddress')}</Typography>
                <InputStyle>
                  <InputBase
                    sx={{ width: '100%', ml: 1, flex: 1, fontSize: '1rem', padding: '0.7rem 0.5rem 0.5rem 1rem' }}
                    value={newWalletAddres}
                    onChange={(e) => setNewWalletAddress(e.currentTarget.value)}
                    disabled={isAddingWallet}
                    error={!!walletAddError}
                  />
                </InputStyle>

                <Typography display={walletAddError ? 'block' : 'none'} color={'red'} fontSize={'12px'}>{walletAddError}</Typography>
              </Container>

            </Stack>

            <Stack direction="row" gap="12px">
              <Box justifyContent={"center"} onClick={addNewWallet} >
                <ContainerButton>
                  <ButtonLabel>{!isAddingWallet ? t('dashboard.miningPool.addWallet') : <CircularProgress size={24} color="inherit" />} </ButtonLabel>
                </ContainerButton>
              </Box>

              <Box justifyContent={"center"} onClick={handleCloseAddMonitWallet}>
                <ContainerTransparentButton>
                  <ButtonLabel>{t('dashboard.miningPool.cancel')}</ButtonLabel>
                </ContainerTransparentButton>
              </Box>
            </Stack>
          </Box>
        </ClickAwayListener>
      ) : null}

      <Stack direction="row" gap="16px" alignItems="center">
        <Typography fontWeight="700">{t('dashboard.miningPool.walletsBalance')}</Typography>
        <Typography fontSize="14px">{t('dashboard.miningPool.beingMonitored')}: {monitoredWallets.length}</Typography>
      </Stack>

      <Stack
        padding="16px"
        borderRadius="16px"
        gap="20px"
        direction={isMobile ? 'column' : 'row'}
        alignItems="flex-start"
        bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#1C1B25"}
        sx={{ overflowX: 'auto' }}
      >
        {/* Wallet List */}
        <Stack
          direction="row"
          gap="20px"
          sx={{
            overflowX: isMobile ? 'auto' : 'visible',
            flex: 1,
            pb: isMobile ? 2 : 0, // Add padding only for mobile scrolling
            '&::-webkit-scrollbar': {
              height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#4C4C4C',
              borderRadius: '8px',
            },
          }}
        >
          {/* Add Wallet Button */}
          <Button
            onClick={handleOpenAddMonitWallet}
            style={{
              border: `1px solid ${localStorage.getItem('mui-mode') === 'light' ? "#E4E2E4" : "#474648"}`,
              background: localStorage.getItem('mui-mode') === "light" ? "#ffffff" : '#191919',
              color: '#F6F1F2',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '232px',
              borderRadius: '16px',
              minHeight: isMobile ? '100px' : '150px',
            }}
          >
            <BiPlus color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#F6F1F2"} size={40} />
            <Typography color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#F6F1F2"} fontSize="14px">{t('dashboard.miningPool.addWallet')}</Typography>
          </Button>

          {monitoredWallets.map((wallet, index) => (
            <Stack
              key={wallet.address}
              direction="column"
              gap="12px"
              padding="16px"
              border={`1px solid ${localStorage.getItem('mui-mode') === 'light' ? "#E4E2E4" : "#474648"}`}
              borderRadius="16px"
              width="232px"
              minHeight="150px"
            >
              {walletBeingRemoved && walletBeingRemoved === wallet.address ? (<>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100%'}>
                  <CircularProgress size={24} color="inherit" />
                </Box>
              </>) : (<>
                <Stack direction="row" alignItems="flex-start" justifyContent={'space-between'}>
                  <Box display="flex" flexDirection={'row'} gap="10px" alignItems="center">
                    <SvgIcon component={defaultProfilePicture} inheritViewBox sx={{ fontSize: 32 }} />

                    <Stack direction="column" gap="4px">
                      <Typography color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#F6F1F2"} fontSize="14px">{t('dashboard.miningPool.account')} {index + 1}</Typography>
                      <Typography fontSize="12px" color="#989899">{wallet.address.slice(0, 4) + '...' + wallet.address.slice(-4)}</Typography>
                    </Stack>
                  </Box>

                  <SvgIcon cursor={'pointer'} component={closeIcon} inheritViewBox sx={{ fontSize: 24, color: localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#F6F1F2" }} onClick={() => removeMonitoredWallet(wallet.address)} />
                </Stack>

                {Object.entries(wallet.assets).map(([key, asset]: any) =>
                  <Stack direction="row" gap="4px" key={asset.key}>
                    <Typography color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#F6F1F2"} fontSize="12px" fontWeight="700">{asset.balance}</Typography>
                    <Typography fontSize="12px" color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#F6F1F2"}>{asset.name}</Typography>
                  </Stack>
                )}
              </>)
              }
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}