export const copyEn = {
  create: "Create your Wallet",
  titleWhite: "Copy your Secret",
  titleBlue: "Recovery Phrase",
  subTitle:
    "Your Secret Recovery Phrase makes it easy to back up and restore your account.",
  never: "Never lose your Secret Recovery Phrase",
  copyPhrase: "Copy phrase",
  enter: "Enter CoNET",
  warning:
    "WARNING: Never disclose your Secret Recovery Phrase. Anyone with this phrase can take your Ether forever.",
};

export const copyZh = {
  create: "创建您的钱包",
  titleWhite: "复制您的",
  titleBlue: "助记词",
  subTitle: "助记词可以帮助您轻松备份或恢复此钱包",
  never: "请妥善保存助记词",
  copyPhrase: "复制助记词",
  enter: "进入 CoNET",
  warning:
    "提示: 切勿泄露您的助记词, 一旦泄露, 钱包内的所有资产都会面临 被盗风险",
};
