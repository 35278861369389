import React, { useState } from "react";
import "./makeHorizontal.css";
import { Box, Stack, SvgIcon, Typography } from "@mui/material";

import { ReactComponent as vpnIcon } from "./assets/updates/silentpassvpn-icon.svg"
import { ReactComponent as claimIcon } from "./assets/updates/claim.svg"
import { ReactComponent as dexIcon } from "./assets/updates/dex.svg"
import { ReactComponent as tgeIcon } from "./assets/updates/tge.svg"

import { useTranslation } from "react-i18next";


const NextUpdates = () => {
    const { t } = useTranslation();

    const updates = [
        {
            "label": "SilentPass VPN",
            "description": "Open for everyone",
            "icon": vpnIcon
        },
        {
            "label": "Airdrop",
            "description": "Redeem your assets",
            "icon": claimIcon
        },
        {
            "label": "TGE",
            "description": "(Token Generation Event)",
            "icon": tgeIcon
        },
        {
            "label": "DEX/CEX",
            "description": "(Decentralized/Centralized Exchange)",
            "icon": dexIcon
        },
    ]

    return (
        <Stack flexDirection={'row'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
            <Stack flexDirection={['column', 'row']} justifyContent={'space-between'} gap={2} width={'1079px'}>
                <Stack alignItems={'flex-start'} >
                    <Typography mb={0} fontWeight={700} align="left" fontSize={'16px'} color={localStorage.getItem('mui-mode') === 'light' ? '#989899' : '#989899'}>Roadmap</Typography>
                    <Typography mb={0} fontWeight={700} align="left" fontSize={'32px'} color={localStorage.getItem('mui-mode') === 'light' ? '#000000' : '#FFFFFF'} minHeight={'32px'}>Keep up with CoNET’s next updates</Typography>
                </Stack>
                <Stack minWidth={'280px'} maxWidth={'380px'} gap={4} position={'relative'}>
                    <Box width={'2px'} height={'100%'} bgcolor={localStorage.getItem('mui-mode') === 'light' ? '#e6ecff' : '#79F8FF'} position={"absolute"} left={'19px'} />
                    {updates.map((update, index) => (
                        <Stack flexDirection={'row'} alignItems={'center'} gap={'8px'} zIndex={2}>
                            <Box bgcolor={localStorage.getItem('mui-mode') === 'light' ? '#8da8ff' : '#131E1E'} borderRadius={'50%'} width={'40px'} height={'40px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <SvgIcon component={update.icon} viewBox="0 0 24 24" sx={{ fontSize: '24px', fill: 'none' }} />
                            </Box>
                            <Typography textAlign={'left'} mb={0} fontWeight={700} align="center" fontSize={'14px'} lineHeight={'16px'} color={localStorage.getItem('mui-mode') === 'light' ? '#000000' : '#FFFFFF'}>{update.label}</Typography>
                            <Typography textAlign={'left'} mb={0} fontWeight={400} align="center" fontSize={'14px'} lineHeight={'14px'} color={localStorage.getItem('mui-mode') === 'light' ? '#989899' : '#989899'}>{update.description}</Typography>
                        </Stack>
                    ))}
                </Stack>

            </Stack>
        </Stack>
    )
};

export default NextUpdates;
