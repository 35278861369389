"use client";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IUserLanguage {
  languages: readonly string[];
  changeLanguage: (value: string) => void;
}

export const useLanguage = (): IUserLanguage => {
  const { i18n } = useTranslation();

  /*    useEffect(() => {
    const browserLanguage = navigator.language || navigator.languages[0];
    changeLanguage(browserLanguage);
  }, []);  */

  const changeLanguage = (language: string) => {
    const supportedLanguages = ["en", "zh"];

    if (supportedLanguages.includes(language)) {
      i18n.changeLanguage(language);
      localStorage.setItem("language", language);
    } else {
      i18n.changeLanguage("zh");
      localStorage.setItem("language", "zn");
    }
  };
  return {
    languages: i18n.languages,
    changeLanguage,
  };
};
