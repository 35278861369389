import { Box } from '@mui/material';
import Messages from './Messages';
import Contacts from './Contacts';
import useAppState from '../../../../store/appState/useAppState';
import { Chat as ChatType, chatsMock, Message } from './types';
import { useEffect, useState } from 'react';

import "./styles.css";

export default function Chat() {
  const { activeProfile } = useAppState();

  const [chats, setChats] = useState(chatsMock);
  const [choosenChatId, setChoosenChatId] = useState<number>();
  const [isContactsOpen, setIsContactsOpen] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function addNewMessage(messageDto: Message) {
    setChats((prevState) =>
      prevState.map((item) => {
        if (item.id !== choosenChatId) return item;

        return {
          ...item,
          messages: [...item.messages, messageDto]
        }
      }, [])
    )
  }

  function chooseChat(chat: ChatType) {
    setChoosenChatId(chat.id);
    setIsContactsOpen(false);
  }

  return (
    <Box
      flex="1" // temp
      display="flex"
      width="100%"
    >
      <Contacts
        chats={chats}
        chooseChat={chooseChat}
        choosenChatId={choosenChatId}
        profile={activeProfile}
        isOpen={isContactsOpen}
        isMobile={isMobile}
      />
      <Messages
        currentChat={chats.find((chat) => chat.id === choosenChatId)}
        addMessage={addNewMessage}
        profile={activeProfile}
        close={() => setIsContactsOpen(true)}
        isMobile={isMobile}
      />
    </Box>
  )
}