import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { gridBlockDefaultStyles, Item, tokens } from '../common';
import { useEffect, useMemo, useState } from 'react';
import { formatNumericPattern } from '../../../../../utilities/utilities';

import conetianLogo from '../assets/conetian_logo.svg';

import { BiChevronDown } from 'react-icons/bi';
import SelectModal from '../modal';
import { useRefreshStore } from '../../../../../API/useRefreshStore';
import { platform } from '../../../../../API/platform';
import { CgSpinner } from 'react-icons/cg';
import { toast } from 'react-toastify';
import { useTypedSelector } from '../../../../../store/store';

export default function Transfer() {
  const walletStore = useRefreshStore(state => state.wallets);

  const [selectedTokenId, setSelectedTokenId] = useState(1);
  const [selectedWalletId, setSelectedWalletId] = useState('');
  const [quantity, setQuantity] = useState("0");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [fieldMouseOver, setFieldMouseOver] = useState(false);
  const [fieldFocused, setIsFieldFocused] = useState(false);

  const [isTokenModalOpen, setIsTokenModalOpen] = useState<boolean>(false);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState<boolean>(false);

  const [workerLoading, setWorkerLoading] = useState<any>();
  const [conetPlatform, setConetPlatform] = useState<any>();

  const [gasFee, setGasFee] = useState("");

  const [isTransferLoading, setIsTransferLoading] = useState<boolean>(false);

  const [currentProfile, setCurrentProfile] = useState<any>();

  const conet_platform = new platform(setConetPlatform, setWorkerLoading);

  const [temporaryWallets, setTemporaryWallets] = useState<any[]>([]);

  const profileIndex = useTypedSelector(
    (state) => state.appState.activeProfile
  );

  const wallets = useMemo(() => [...walletStore, ...temporaryWallets].map((wallet) => ({
    id: wallet.keyID,
    name: 'Anonymous User',
    slug: wallet.keyID,
    icon: conetianLogo,
  })), [walletStore, temporaryWallets]);

  const selectedToken = useMemo(() => tokens.find((token) => token.id === selectedTokenId)!, [selectedTokenId]);
  const selectedWallet = useMemo(() =>
    [...walletStore, ...temporaryWallets].find((wallet) => wallet.keyID === selectedWalletId),
    [selectedWalletId, temporaryWallets, walletStore]
  );

  function handleSelectToken(token: Item) {
    setSelectedTokenId(token.id);
  }

  function handleSelectWallet(wallet: any) {
    setSelectedWalletId(wallet.id);
    setGasFee("");
  }

  function handleAddNewWallet(walletKeyID: any) {
    setTemporaryWallets((prev) => ([
      ...prev,
      {
        name: 'Anonymous User',
        slug: walletKeyID?.substring(0, 2) + walletKeyID?.substring(2, 8).toUpperCase() + '...' + walletKeyID?.substring(walletKeyID?.length - 6, walletKeyID?.length).toUpperCase(),
        icon: conetianLogo,
        keyID: walletKeyID,
      }
    ]))

    toast.success("New temporary wallet added.");
  }

  async function handleTransfer() {
    if (!quantity || !selectedWallet || !currentProfile) return;

    try {
      setIsTransferLoading(true);

      if (!gasFee) {
        const gasFee = await conet_platform.estimateGas(
          Number(quantity),
          currentProfile.keyID,
          selectedToken.slug!,
          selectedWallet.keyID,
        )

        setGasFee(gasFee[1]);

        setTimeout(() => {
          setGasFee("");
        }, 60000)
      } else {
        await conet_platform.transferToken(
          Number(quantity),
          currentProfile.keyID,
          selectedToken.slug!,
          selectedWallet.keyID,
        );

        setQuantity('0')
        setSelectedWalletId('')
        setTemporaryWallets([]);
        toast.success("Transfer successful.", { position: "top-center" });
      }
    } catch (err: any) {
      console.log("ERR: ", err);
    } finally {
      setIsTransferLoading(false);
    }
  }

  useEffect(() => {
    if (gasFee === "") return;
    setGasFee("");
  }, [quantity]);

  useEffect(() => {
    const updateProfile = () => {
      const keyID = profileIndex?.keyID?.toLowerCase() || "0";
      const profiles = walletStore && walletStore.length > 0 ? walletStore : [];
      if (!profiles) return false;
      const index = profiles.findIndex((n) => n.keyID.toLowerCase() === keyID);
      if (index < 0) {
        return;
      }

      setCurrentProfile(profiles[index]);
    };
    updateProfile();
  }, [walletStore]);

  const renderSelectedWalletName = () => {
    if (!selectedWallet) {
      return ''
    }

    if (!selectedWallet?.data) {
      return 'Anonymous User'
    }

    if (!selectedWallet?.data?.nickname) {
      return 'Anonymous User'
    }

    return selectedWallet?.data?.nickname
  }

  return (
    <>
      <Stack padding={'0 16px'} minWidth={['0', '656px']} width={'100%'} alignItems={'center'} justifyContent={'center'}>
        <Stack
          marginTop="16px"
          marginBottom="8px"
          padding="16px"
          width="100%"
          maxWidth="656px"
          borderRadius="16px"
          bgcolor={theme.palette.mode === "light" ? "#F8F8FA" : "#1B1B1B"}
          color={theme.palette.mode === "light" ? "black" : "white"}
        >
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gridTemplateRows: 'repeat(10, 1fr)', gap: '8px' }}>
            <Box
              onMouseOver={() => setFieldMouseOver(true)}
              onMouseOut={() => setFieldMouseOver(false)}
              sx={{
                gridRow: 'span 5', ...gridBlockDefaultStyles,
                flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', gap: '20px',
                background: (fieldMouseOver && !fieldFocused) ? theme.palette.mode === "light" ? "#F0F0F2" : "#262626" : theme.palette.mode === "light" ? "#F8F8FA" : "#0D0D0D",
                border: `1px solid ${fieldFocused ? theme.palette.mode === "light" ? "#DCDCDC" : "#4C4C4C" : fieldMouseOver ? theme.palette.mode === "light" ? "F0F0F2" : "#262626" : theme.palette.mode === "light" ? "#F0F0F2" : "#0D0D0D"}`
              }}
            >
              <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
                <Typography color="#989899" fontSize="14px" alignSelf="flex-start">Send</Typography>
                {
                  selectedToken && currentProfile && (
                    <Typography color="#989899" fontSize="14px">Balance <strong>{currentProfile.tokens[selectedToken.slug!].balance}</strong></Typography>
                  )
                }
              </Stack>
              <Stack alignItems="center" gap="24px">
                <input
                  onFocus={() => setIsFieldFocused(true)} onBlur={() => setIsFieldFocused(false)}
                  value={quantity} onChange={(e) => setQuantity(formatNumericPattern(e.target.value))}
                  style={{ fontSize: isMobile ? '38px' : '60px', textAlign: 'center', width: '100%', color: theme.palette.mode === "light" ? "#000" : "#FFF", background: 'transparent', border: 0 }}
                />
                <button className="button-ptn" onClick={() => setIsTokenModalOpen(true)}>
                  <img src={selectedToken.icon} alt={selectedToken.name} width={32} height={32} />
                  <Typography color={theme.palette.mode === "light" ? "#000" : "#FFF"}>{selectedToken.name}</Typography>
                  <BiChevronDown color={theme.palette.mode === "light" ? "#000" : "#FFF"} />
                </button>
              </Stack>
            </Box>
            <button style={{ gridRow: 'span 3', ...gridBlockDefaultStyles, border: "none", background: theme.palette.mode === "light" ? "#F8F8FA" : "#0D0D0D" } as any} onClick={() => setIsWalletModalOpen(true)}>
              <Typography color="#989899" fontSize="14px">To: {renderSelectedWalletName()}</Typography>
              {
                selectedWallet && (
                  <Typography color={theme.palette.mode === "light" ? "#000" : "#FFF"} fontSize={isMobile ? "18px" : "36px"}>
                    {selectedWallet.keyID?.substring(0, 2) + selectedWallet.keyID?.substring(2, 7).toUpperCase() + '...' + selectedWallet.keyID?.substring(selectedWallet.keyID?.length - 5, selectedWallet.keyID?.length).toUpperCase()}
                  </Typography>
                )
              }
            </button>
            <Button sx={{ gridRow: 'span 2', ...gridBlockDefaultStyles, background: theme.palette.mode === "light" ? "#577DFF1A" : "#79F8FF1A", color: theme.palette.mode === "light" ? "#577DFF" : "#79F8FF", alignItems: "center" }} onClick={handleTransfer}>
              {
                isTransferLoading ? (
                  <CgSpinner size={22} className="animate-spin" />
                ) : (
                  <Typography fontWeight="700">{gasFee ? "Transfer" : "Estimate Gas Fee"}</Typography>
                )
              }
            </Button>
          </Box>
        </Stack>
        {
          gasFee && (
            <Stack width="100%" maxWidth="656px">
              <Typography alignSelf="flex-end" color="#676768">Gas Cost <strong>$CONET {gasFee}</strong></Typography>
            </Stack>
          )
        }
      </Stack>
      <SelectModal
        title="Select a token"
        name="Tokens"
        searchPlaceholder="Search a token here..."
        items={tokens}
        handleItemSelect={handleSelectToken}
        open={isTokenModalOpen} onClose={() => setIsTokenModalOpen(false)}
      />
      <SelectModal
        title="Select a wallet"
        name="Wallets"
        searchPlaceholder="Search a wallet or type a new one here..."
        items={wallets}
        handleItemSelect={handleSelectWallet}
        open={isWalletModalOpen} onClose={() => setIsWalletModalOpen(false)}
        inputAction={handleAddNewWallet}
      />
    </>
  )
}