import { Box, Button, Grid, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useRefreshStore } from '../../../../../API/useRefreshStore';
import { useMemo, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { gridBlockDefaultStyles, Item, networks, tokens } from '../common';
import { formatNumericPattern } from '../../../../../utilities/utilities';
import { SwapVert } from '@mui/icons-material';
import SelectModal from '../modal';

export default function Swap() {
  const walletState = useRefreshStore((state) => state.wallets);
  const theme = useTheme();

  const [choosenWallet, setChoosenWallet] = useState(walletState[0]);
  const [selectedNetworkId, setSelectedNetworkId] = useState(1);
  const [fromAsset, setFromAsset] = useState(1);
  const [toAsset, setToAsset] = useState(2);
  const [faQtd, setFaQtd] = useState("0");
  const [taQtd, setTaQtd] = useState("0");

  const [fieldSellMouseOver, setFieldSellMouseOver] = useState(false);
  const [fieldSellFocused, setIsFieldSellFocused] = useState(false);

  const [fieldBuyMouseOver, setFieldBuyMouseOver] = useState(false);
  const [fieldBuyFocused, setIsFieldBuyFocused] = useState(false);

  const [isNetworkModalOpen, setIsNetworkModalOpen] = useState(false);
  const [isSellTokenModalOpen, setIsSellTokenModalOpen] = useState(false);
  const [isBuyTokenModalOpen, setIsBuyTokenModalOpen] = useState(false);

  const selectedNetwork = useMemo(() => networks.find((network) => network.id === selectedNetworkId)!, [selectedNetworkId]);
  const selectedFaToken = useMemo(() => tokens.find((token) => token.id === fromAsset)!, [fromAsset]);
  const selectedTaToken = useMemo(() => tokens.find((token) => token.id === toAsset)!, [toAsset]);

  function handleChangeWallet(value: string) {
    const currentWallet = walletState.find((wallet) => wallet.keyID === value);

    setChoosenWallet(currentWallet)
  }

  function maximizeFromAsset() {

  }

  function getQuotes() {

  }

  function openTermsAndService() {

  }

  function invertAssets() {
    const firstAsset = fromAsset;

    setFromAsset(toAsset);
    setToAsset(firstAsset);
  }


  return (
    <>
      <Stack padding={'0 16px'} minWidth={['0', '656px']} width={'100%'} alignItems={'center'} justifyContent={'center'}>
        <Stack
          marginTop="16px"
          marginBottom="8px"
          padding="16px"
          width="100%"
          maxWidth="656px"
          borderRadius="16px"
          bgcolor={theme.palette.mode === "light" ? "#F3F0F2" : "#1B1B1B"}
          color={theme.palette.mode === "light" ? "black" : "white"}
        >
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(8, 1fr)', gridTemplateRows: 'repeat(7, 1fr)', gap: '8px' }}>
            <Box sx={{ gridRow: '1 / 3', gridColumn: '1 / 9', ...gridBlockDefaultStyles, alignItems: 'center', gap: '20px' }}>
              <Typography color="#989899" fontSize="14px" alignSelf="flex-start">Network</Typography>
              <button className="button-ptn" style={{ width: "100%", justifyContent: "space-between" }} onClick={() => setIsNetworkModalOpen(true)}>
                <Stack direction="row" alignItems="center" gap="8px">
                  <img src={selectedNetwork.icon} alt={selectedNetwork.name} width={32} height={32} />
                  <Typography color="#FFF">{selectedNetwork.name}</Typography>
                </Stack>
                <BiChevronDown color="#FFF" />
              </button>
            </Box>
            <Box
              onMouseOver={() => setFieldSellMouseOver(true)}
              onMouseOut={() => setFieldSellMouseOver(false)}
              sx={{
                gridRow: '3 / 5', gridColumn: '1 / 8', ...gridBlockDefaultStyles,
                flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '20px',
                background: (fieldSellMouseOver && !fieldSellFocused) ? theme.palette.mode === "light" ? "#F0F0F2" : "#262626" : theme.palette.mode === "light" ? "#F8F8FA" : "#0D0D0D",
                border: `1px solid ${fieldSellFocused ? theme.palette.mode === "light" ? "#DCDCDC" : "#4C4C4C" : fieldSellMouseOver ? theme.palette.mode === "light" ? "F0F0F2" :  "#262626" : theme.palette.mode === "light" ? "#F0F0F2" : "#0D0D0D"}`
              }}
            >
              <Stack gap="8px">
                <Typography color="#989899" fontSize="14px">Sell</Typography>
                <input
                onFocus={() => setIsFieldSellFocused(true)} onBlur={() => setIsFieldSellFocused(false)}
                  value={faQtd} onChange={(e) => setFaQtd(formatNumericPattern(e.target.value))}
                  style={{ fontSize: '60px', width: '100%', color: '#FFF', background: 'transparent', border: 0 }}
                />
              </Stack>
              <button className="button-ptn" onClick={() => setIsSellTokenModalOpen(true)}>
                <img src={selectedFaToken.icon} alt={selectedFaToken.name} width={32} height={32} />
                <Typography color="#FFF">{selectedFaToken.name}</Typography>
                <BiChevronDown color="#FFF" />
              </button>
            </Box>
            <Box
              onMouseOver={() => setFieldBuyMouseOver(true)}
              onMouseOut={() => setFieldBuyMouseOver(false)}
              sx={{
                gridRow: '5 / 7', gridColumn: '1 / 8', ...gridBlockDefaultStyles,
                flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '20px',
                background: (fieldBuyMouseOver && !fieldBuyFocused) ? theme.palette.mode === "light" ? "#F0F0F2" : "#262626" : theme.palette.mode === "light" ? "#F8F8FA" : "#0D0D0D",
                border: `1px solid ${fieldBuyFocused ? theme.palette.mode === "light" ? "#DCDCDC" : "#4C4C4C" : fieldBuyMouseOver ? theme.palette.mode === "light" ? "F0F0F2" :  "#262626" : theme.palette.mode === "light" ? "#F0F0F2" : "#0D0D0D"}`
              }}
            >
              <Stack gap="8px">
                <Typography color="#989899" fontSize="14px">Buy</Typography>
                <input
                  onFocus={() => setIsFieldBuyFocused(true)} onBlur={() => setIsFieldBuyFocused(false)}
                  value={taQtd} onChange={(e) => setTaQtd(formatNumericPattern(e.target.value))}
                  style={{ fontSize: '60px', width: '100%', color: '#FFF', background: 'transparent', border: 0 }}
                />
              </Stack>
              <button className="button-ptn" onClick={() => setIsBuyTokenModalOpen(true)}>
                <img src={selectedTaToken.icon} alt={selectedTaToken.name} width={32} height={32} />
                <Typography color="#FFF">{selectedTaToken.name}</Typography>
                <BiChevronDown color="#FFF" />
              </button>
            </Box>
            <button
              onClick={invertAssets}
              style={{ ...(gridBlockDefaultStyles as any), border: 'none', gridRow: '3 / 7', gridColumn: '8 / 9', justifyContent: 'center', alignItems: 'center', }}
            >
              <SwapVert sx={{ color: '#FFF', fontSize: '30px' }} />
            </button>
            <Button sx={{ gridRow: '7 / 8', gridColumn: '1 / 9', ...gridBlockDefaultStyles, background: "#79F8FF1A", color: "#79F8FF", alignItems: "center"  }} disabled={toAsset === fromAsset}>
              <Typography fontWeight="700">Swap</Typography>
            </Button>
          </Box>
        </Stack>
        <Stack width="100%" maxWidth="656px">
          <Typography alignSelf="flex-end" color="#676768">Gas Cost <strong>$5</strong></Typography>
        </Stack>
      </Stack>
      <SelectModal
        title="Select a network"
        name="Networks"
        searchPlaceholder="Search a network here..."
        items={networks}
        handleItemSelect={(item: Item) => setSelectedNetworkId(item.id)}
        open={isNetworkModalOpen}
        onClose={() => setIsNetworkModalOpen(false)}
      />
      <SelectModal
        title="Select a token"
        name="Tokens"
        searchPlaceholder="Search a token here..."
        items={tokens}
        handleItemSelect={(item: Item) => setFromAsset(item.id)}
        open={isSellTokenModalOpen}
        onClose={() => setIsSellTokenModalOpen(false)}
      />
      <SelectModal
        title="Select a token"
        name="Tokens"
        searchPlaceholder="Search a token here..."
        items={tokens}
        handleItemSelect={(item: Item) => setToAsset(item.id)}
        open={isBuyTokenModalOpen}
        onClose={() => setIsBuyTokenModalOpen(false)}
      />
    </>
  )
}