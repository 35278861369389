import { styled as materialStyled } from '@mui/material'
import Stack from '@mui/material/Stack'
import React, { HTMLAttributes, useState, useEffect, SyntheticEvent, ReactNode, forwardRef } from "react"
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'
import { useIntl } from "react-intl"
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ErrorIcon from '@mui/icons-material/Error'
import CircularProgress from '@mui/material/CircularProgress'
import DirectionsIcon from '@mui/icons-material/Directions'
import Box from '@mui/material/Box'
import Button from '@mui/material-next/Button'
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { styled } from '@mui/material/styles'
import { unlockPasscodePlatform } from '../../../../services/workerService/workerService'
import useAppState from "../../../../store/appState/useAppState"
import { encrypt_deletePasscode } from '../../../../API/index'
import { Container } from '@mui/material'
import { ButtonLabel, ContainerButton, LinkText, SpanTitle } from './styles'
import { LogoIcon } from "../../../UI/Logo/Logo"
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { LogoLight, LogoDark } from './WelcomeToConet'
import { border, borderColor } from '@mui/system'
import { useTranslation } from "react-i18next"
import LanguageSwitcher from './LanguageSwitcher'

const InputStyle = materialStyled(Paper)(() => ({
    display: 'flex',
    textAlign: 'center',
    borderRadius: '1rem',
    border: '1px solid #E4E2E4',
    width: '380px',
    height: '52px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    justifyContent: 'center'
}))

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),

    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),

    },
    '& .MuiDialog-paper': {
        padding: theme.spacing(3),
    }
}))

interface WelcomeToConetProps {
    onClickStep: (number: number) => void,
    setProfileVer: React.Dispatch<React.SetStateAction<number>>,
    testPasscode: (password: string, profileVerHook: React.Dispatch<React.SetStateAction<number>>) => Promise<[boolean, string]>,
    getAllProfiles: (password: string) => Promise<any>,
    importWallet: (authorizationkey: string, privateKey: string) => Promise<any[]>
    updateProfile: (authorizationkey: string, profile: any) => Promise<any[]>
    listSrp: string[]
}

const UnLockWallet = ({ onClickStep, setProfileVer, testPasscode, getAllProfiles, importWallet, updateProfile, listSrp }: WelcomeToConetProps) => {
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState(false)
    const [show, setShow] = useState(false);
    const [open, setOpen] = React.useState(false)
    const [fruitsInBasket, setFruitsInBasket] = useState<null | HTMLElement>(null)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [sucessUnLockWallet, setSucessUnLockWallet] = useState(false)

    const {
        locale,
        setLocale,
        showAppStore,
        localDaemon,
        hasContainer,
        isInitializing,
        setlocalDaemon,
        isUnlocked,
        theme,
        setTheme,
        showMiner,
        dAPPInitialize
    } = useAppState()
    const intl = useIntl()

    const { t } = useTranslation()

    const handleClick = () => setShow(!show);
    const processUnlockPassword = async () => {
        if (password.length < 6) {
            setTimeout(() => {
                setPasswordError(false)
            }, 2000)
            return setPasswordError(true)
        }
        setLoading(true)
        const passcode = password
        // const sucessPasswrod = await testPasscode(passcode)
        // console.log("sucessPasswrod: ", sucessPasswrod)
        // if(sucessPasswrod[0]){
        //     const getProfile = await getAllProfiles(sucessPasswrod[1])
        //     console.log("getProfile: ", getProfile)
        // }
        unlockPasscodePlatform({
            passcode, setProfileVer, testPasscode, getAllProfiles, importWallet, listSrp, updateProfile, locale, progress: (progress) => {
                //	get process 
            }
        }).then(status => {
            setLoading(false)
            if (status === 'SUCCESS') {
                setProfileVer(6)

                const kkk = isUnlocked
                const lll = hasContainer
            }
            return setPasswordError(true)
        })
    }

    const keyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        if (key == 'Enter') {
            return processUnlockPassword()
        }
    }
    const handleClose = () => {
        setOpen(false)
    }

    const forgotPassword = () => {
        onClickStep(4)
    }

    const handleAddFruit = () => {
        setOpen(true)
    }


    return (
        <Container maxWidth='lg' sx={{ height: '100%', width: '100%', overflowX: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: { xs: '0', md: '1rem' } }}>
            <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', height: '100vh' }}>
                <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: { xs: '0', md: '1rem' } }}>
                    {localStorage.getItem('mui-mode') == 'dark' ? <LogoDark /> : <LogoLight />}
                    <Typography style={{ textTransform: 'uppercase', fontWeight: '700', fontSize: '14px' }}>{t('unlockWallet.welcome')}</Typography>
                    <Typography
                        style={{
                            fontSize: '32px',
                            fontWeight: '400',
                            marginTop: '40px',
                        }}
                    >
                        {t('unlockWallet.titleWhite')} <SpanTitle>{t('unlockWallet.titleBlue')}</SpanTitle>
                    </Typography>
                    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '32px', padding: { xs: '-2rem', md: '1rem' } }} {...passwordError ? { backgroundColor: 'red' } : null}>
                        <Typography style={{ fontSize: '16px', fontWeight: '400', width: '100%', maxWidth: '380px' }}>{t('unlockWallet.password')}</Typography>

                        <InputStyle style={{ width: '100%', maxWidth: '380px' }}>
                            <InputBase
                                sx={{ width: '100%', ml: 1, flex: 1, fontSize: '1rem', padding: '0.7rem 0.5rem 0.5rem 1rem' }}
                                disabled={loading}
                                id="outlined-password-input"
                                placeholder={t('unlockWallet.inputPassword')}
                                type={show ? 'text' : "password"}
                                onChange={e => {
                                    setPasswordError(false)
                                    setPassword(e.target.value)
                                }}
                                error={passwordError}
                                onKeyUp={keyUpHandler}
                            />
                            <>
                                <IconButton tabIndex={-1} onClick={handleClick}>
                                    {show ?
                                        <VisibilityOutlinedIcon /> :
                                        <VisibilityOffOutlinedIcon />
                                    }
                                </IconButton>
                            </>
                        </InputStyle>
                        {passwordError ?
                            <Container style={{ width: '100%', maxWidth: '380px', padding: '0' }}>
                                <Typography textAlign={'left'} fontSize={'14px'} marginTop={'4px'} color={'red'}>{t('unlockWallet.invalidPassword')}</Typography>
                            </Container>
                            :
                            <></>
                        }

                        <Container style={{ width: '100%', maxWidth: '380px', padding: '0' }}>
                            <LinkText onClick={forgotPassword} style={{ cursor: 'pointer', width: "fit-content" }}>{t('unlockWallet.forgot')}</LinkText>
                        </Container>
                    </Container>
                </Container>

                <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: { xs: '2rem', md: '1rem' } }}>
                    <ContainerButton disabled={password.length < 6} onClick={processUnlockPassword} style={{ maxWidth: '380px' }}>
                        {
                            loading
                                ? <CircularProgress size={24} color="inherit" />
                                : <ButtonLabel>{t('unlockWallet.unlock')}</ButtonLabel>
                        }
                    </ContainerButton>

                    <Box marginTop={'16px'}>
                        <LanguageSwitcher />
                    </Box>
                </Container>
            </Container>
        </Container>
    )
}

export default UnLockWallet