import { ChevronRight } from '@mui/icons-material';
import { Box, List, Stack, Typography, useTheme } from '@mui/material'
import { useMemo, useState } from 'react'

const nodes = [
  {
    id: 1,
    highlightTitle: 'Validator',
    caption: 'Critical Network Support for Silent Pass and More',
    topics: [
      {
        title: 'Role',
        text: 'Validator Nodes are responsible for verifying transactions on the CoNET DePIN Blockchain. As validators, these nodes are crucial to maintaining the security and integrity of the decentralized infrastructure.',
      },
      {
        title: 'Earnings',
        text: 'Validator Nodes earn rewards in $ETH, as it built on the CoNET DePIN Blockchain. This provides a daily income stream for validator operators, paid through transaction fees on the network.',
      },
      {
        title: 'Node Cap',
        text: 'Validator Nodes are limited to 20,000, ensuring that early adopters gain privileged positions within the CoNET ecosystem. To reward our early investors, this number will remain fixed for at least five years.',
      },
      {
        title: 'Requirements',
        items: [
          {
            title: 'Guardian Plan Holders',
            description: 'Purchasers of the Guardian Plan have an exclusive right to become Validator Nodes without the need for staking.',
          }, {
            title: 'Staking Requirement',
            description: 'For others wishing to become validators, a stake of amount of $ETH is required.',
          }, {
            title: 'Mandatory Cloud Node',
            description: 'Full nodes require a cloud node service subscription at $5 per month to ensure consistent internet quality and performance 24/7 without need of being online.',
          }
        ]
      }
    ]
  },
  {
    id: 2,
    highlightTitle: 'Full',
    caption: 'Critical Network Support for Silent Pass and More',
    topics: [
      {
        title: 'Role',
        text: 'Full Nodes serve as the backbone of the CoNET DePIN network, handling Proof of Time and Proof of Work verifications. They also play a vital role in running services like Silent Pass, CoNET’s decentralized VPN, by sharing their IP address, bandwidth, and CPU resources. Full Nodes can optionally contribute storage to further support the network.',
      },
      {
        title: 'Earnings',
        text: 'Full Nodes earn higher rewards due to their critical role in maintaining network operations and providing key services like Silent Pass.',
      },
      {
        title: 'Responsabilities',
        items: [
          {
            text: 'Validate proof of work and proof of staking.',
          }, {
            text: 'Act as servers for Silent Pass and other CoNET services.'
          }, {
            text: 'Share IP addresses, bandwidth, CPU, and optionally, storage.'
          }
        ]
      },
      {
        title: 'Requirements',
        items: [
          {
            title: 'Staking Required',
            description: 'Full Nodes must stake a certain amount of $CONET tokens to participate.',
          }, {
            title: 'Mandatory Cloud Node',
            description: 'Full nodes require a cloud node service subscription at $5 per month to ensure consistent internet quality and performance 24/7 without need of being online.',
          }
        ]
      }
    ]
  },
  {
    id: 3,
    highlightTitle: 'Partial',
    caption: 'Supporting the Network through Resource Sharing',
    topics: [
      {
        title: 'Role',
        text: 'Partial Nodes contribute to the network by sharing CPU and bandwidth without needing to share their IP address. They also have the option to share storage. While they may not play as central a role as Full Nodes, they are essential for validating social tasks and contributing to the overall DePIN network.',
      },
      {
        title: 'Earnings',
        text: 'Partial Nodes earn rewards for their contributions, which include validating social tasks and performing SaaS (Software as a Service) validations.',
      },
      {
        title: 'Responsabilities',
        items: [
          {
            text: 'Validate social tasks such as verifying the completion of social tasks across various platforms using CoNET’s decentralized API.'
          }, {
            text: 'Participate in SaaS activities like validating software services within the network.'
          }, {
            text: 'Optionally share storage for additional rewards.'
          }
        ]
      },
      {
        title: 'Requirements',
        items: [
          {
            title: 'Staking Required',
            description: 'Partial Nodes require a smaller amount of $CONET tokens staked compared to Full Nodes, making them accessible to a wider range of participants.',
          }, {
            title: 'Cloud Node Option',
            description: 'Partial nodes may choose to purchase a cloud node service for $5 per month to enhance connectivity and stability and for mining 24/7 without need of being online(optional).',
          }
        ]
      }
    ]
  },
  {
    id: 4,
    highlightTitle: 'Free',
    caption: 'Accessible Participation through Browser-Based Mining',
    topics: [
      {
        title: 'Role',
        text: 'Browser Mining allows users to participate without specialized hardware, using only a web browser. This option is available for users mining through CoNET’s minigame (on browser or Telegram) or on third-party applications that support CoNET mining like FX168 app or website where users are mining while using this platform.',
      },
      {
        title: 'Earnings',
        text: 'Browser Miners earn $CONET rewards based on their mining activity, making it an accessible entry point for new users.',
      },
      {
        title: 'Responsabilities',
        items: [
          {
            text: 'Validate social tasks such as verifying the completion of social tasks across various platforms using CoNET’s decentralized API.',
          }, {
            text: 'Participate in SaaS activities like validating software services within the network.',
          }, {
            text: 'Optionally share storage for additional rewards.',
          }
        ]
      },
      {
        title: 'Requirements',
        items: [
          {
            title: 'No staking or device requirements',
            description: 'simply use a supported browser to start mining.',
          }
        ]
      }
    ]
  }
]

export default function ConetNodes() {
  const theme = useTheme();
  const [choosenNodeId, setChoosenNodeId] = useState(1);

  const selectedNode = useMemo(() => nodes.find((node) => node.id === choosenNodeId)!, [choosenNodeId]);

  return (
    <Stack direction={['column', 'row', 'row', 'row']} justifyContent="space-between" gap="48px" padding="0 32px">
      <Stack gap="24px" alignItems="flex-start" flex="1">
        <Typography fontSize="24px">CoNET Nodes</Typography>
        <Typography fontSize="30px" maxWidth="320px"><span style={{ color: theme.palette.mode === 'light' ? '#577DFF' : '#79F8FF' }}>Get Involved</span> and <span style={{ color: theme.palette.mode === 'light' ? '#577DFF' : '#79F8FF' }}>Earn</span> with Our DePIN Network</Typography>
        <Typography>CoNET invites users to join its decentralized infrastructure by operating nodes on the CoNET DePIN Blockchain. Contribute to network security, share resources, and earn daily rewards. Three types of nodes offer unique roles, requirements, and earning opportunities.</Typography>
        <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(2, 1fr)', gridTemplateColumns: 'repeat(2, 1fr)', gap: '16px' }}>
          {
            nodes.map((node) => (
              <button
                className="node-button"
                onClick={() => setChoosenNodeId(node.id)}
                style={
                  choosenNodeId === node.id ? {
                    background: '#00000033',
                    border: '1px solid #9FBFE5FE',
                    color: theme.palette.mode === 'light' ? '#000' : '#FFF',
                  } : {
                    background: '#FFFFFF0A',
                    border: '1px solid #FFFFFF0A',
                    color: '#8DA8FF',
                  }
                }
              >{node.highlightTitle} Node</button>
            ))
          }
        </Box>
        <button style={{ background: 'none', border: 'none', color: '#8DA8FF' }}>
          <span>Learn More</span>
          <ChevronRight />
        </button>
      </Stack>
      <Stack bgcolor={theme.palette.mode === 'light' ? '#F6F8FF' : '#17181F'} padding="24px 16px" border="1px solid #363E59" borderRadius="16px" gap="16px" flex="1">
        <Stack direction="row" alignItems="center" gap="8px" maxWidth="161px">
          <Typography fontSize="24px"><span style={{ color: theme.palette.mode === 'light' ? '#577DFF' : '#79F8FF', fontWeight: '600' }}>{selectedNode.highlightTitle}</span> Node</Typography>
        </Stack>
        <Typography maxWidth="193px" fontWeight="700" color={theme.palette.mode === 'light' ? '#17181F' : '#F6F8FF'}>{selectedNode.caption}</Typography>
        <Box style={{ width: '100%', height: '1px', background: 'linear-gradient(90.9deg, rgba(121, 248, 255, 0) 0.47%, #79F8FF 50.82%, #D775FF 76%, rgba(215, 117, 255, 0) 101.18%)' }}></Box>
        {
          selectedNode.topics.map((topic) => (
            <Stack gap="4px">
              <Typography fontSize="14px" color="#D775FF">{topic.title}</Typography>
              {
                topic.text && (
                  <Typography fontSize="12px" color={theme.palette.mode === 'light' ? '#17181F' : '#F6F8FF'}>{topic.text}</Typography>
                )
              }
              {
                topic.items && topic.items.map((item) => (
                  <ul style={{ marginLeft: '12px' }}>
                    {
                      'text' in item && (
                        <li style={{ fontSize: '14px', color: theme.palette.mode === 'light' ? '#17181F' : '#F6F8FF' }}>{item.text!}</li>
                      )
                    }
                    {
                      ('title' in item && 'description' in item) && (
                        <li style={{ fontSize: '14px',  }}><strong>{item.title}: </strong>{item.description}</li>
                      )
                    }
                  </ul>
                ))
              }
            </Stack>
          ))
        }
      </Stack>
    </Stack>
  )
}
